import React from "react";

import {
  Box,
  Grid,
  styled,
  Typography,
  Button,
  Modal,
  TextField
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import "../../dashboard/src/dashboard.css"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Calendar_Icon, Comment_Icon, cross, goal_Icon, Empty } from './assets';
import moment from "moment";
import Loader from "../../../components/src/Loader";
// Customizable Area End

import AddKpiController, { KpisDKR, KpisDKROption, Props } from "./AddKpiController";

export default class AddKpiReport extends AddKpiController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderNoRecords = () => {
    return (<>
      <div className="no-records-alert">
        <img src={Empty} alt="logo" width={175} height={125} />
        <h3>No Records Found!</h3>
        <h5>There are no records to display right now.</h5>
      </div>
    </>)
  }

  renderInputBox = (item: KpisDKR ,dkrName : KpisDKROption,  index : number) => {
    return (<>
      {(item.DKR_response_type[index] === null) && (
        <input
          key={`input-${index}`}
          type="number"
          data-testId="dkrInputValueTestID"
          placeholder="Enter value*"
          style={webStyles.TextInput}
          className="textInput"
          name={`Sales-${index}`}
          value={this.state.dkrCommentData.find(comment => comment.commentable_id === dkrName.id)?.dkr_input || ""}
          onChange={(event) => this.handleDkrInput(event, dkrName.id)}
        />
      )}
      {item.DKR_response_type[index] === "manual" && (
        <input
          data-testId="dkrInputValueTestID"
          type="number"
          placeholder="Enter value*"
          style={webStyles.TextInput}
          className="textInput"
          name={`Sales-${index}`}
          value={this.state.dkrCommentData.find(comment => comment.commentable_id === dkrName.id)?.dkr_input || ""}
          onChange={(event) => this.handleDkrInput(event, dkrName.id)}
        />
      )}

      {item.DKR_response_type[index] === "dropdown" && (
        <select
          name={`Converted-${index}`}
          data-testId="dkrInputValueTestID"
          id={`Converted-${index}`}
          style={webStyles.selectBox}
          className="textInput"
          value={this.state.dkrCommentData.find((comment) => comment.commentable_id === dkrName.dkr_id)?.dkr_input || ""}
          onChange={(event) => this.handleDkrInput(event, dkrName.dkr_id)}
        >
          <option value="">Select Option*</option>
          {item.options?.map((option, idx) =>
            option.dkr_responses?.map((res, resIdx) => (
              <option key={`${idx}-${resIdx}`} value={res.options}>
                {res.options}
              </option>
            ))
          )}
        </select>
      )}
    </>)
  }

  renderDkrInput = () =>{
    const { kpisDkrData } = this.state;
    return(<>
     {kpisDkrData?.map((item, indexKpi) =>{
                const dKR_Title = item.options;
              return (
              <Box  display={'flex'} key={indexKpi} justifyContent={'space-between'}>
                <DkrBox>
                  <KpiTitle>{item.KPI}</KpiTitle>
                  <Box style={webStyles.DKRContainer}>
                
                  {item.kpi_type === "checklist" ? (
                     <Box style={webStyles.kpiDescription}>{item?.DKR_description}</Box>
                  ): (
                          <>
                            {dKR_Title?.map((dkrName, index)=>(
                  <Box>
                  <Box key={index} style={webStyles.DKRBox}>
                            <DkrTitleBox >
                              <img src={goal_Icon} style={{ height: '20px', width: '30px' }} />
                              <DkrTitleTypography>{dkrName.title}</DkrTitleTypography>
                            </DkrTitleBox>
                            <InputBox>
                            <InputContainer>
                              {this.renderInputBox(item, dkrName , index)}
                            </InputContainer>
                            <RemarkButtonBox data-testId="remarkIconTestID" onClick={() => this.newHandleClick(dkrName.title, dkrName.id ? dkrName.id : dkrName.dkr_id)}><img data-testId="remarkIconTestID" className="remark_img" alt="openModel" src={Comment_Icon} /></RemarkButtonBox>
                            </InputBox>
                  </Box>
                  <InputResponsiveBox>
                  {this.renderInputBox(item, dkrName , index)}
                  </InputResponsiveBox>
                  </Box>            
                        ))}
                          </>
                  )}
                  </Box>
                </DkrBox>
              </Box>
            )})}
    </>)
  }

  // Customizable Area End

  render() {
    const { kpisDkrData } = this.state;
    return (
      // Customizable Area Start
      <>
      <Loader loading={this.state.loading} />
      <Box style={webStyles.maincontainer}>
        <Box style={webStyles.headerSection}>
          <Grid container justifyContent="space-between" alignItems="center">
            <GridHead item>
              <ArrowBackIosIcon data-test-id="EmployeeDashboardId" onClick={()=>this.handleBack("EmployeeDashboard")} style={{ fontSize: '18px', marginRight: '20px',color:"#000000",cursor:'pointer' }} />
              <span style={webStyles.labelStyle1}>Daily KPI Report</span>
            </GridHead>
            <GridDate item>
              <Box className="calendarWrapper" >
                <div style={webStyles.imgDate}>
                  <p style={webStyles.calendarImg}>
                    <img style={{ height: '24px' }} src={Calendar_Icon} alt="calendar_icon" />
                  </p>
                  <span style={{...webStyles.calendarWrapperSpan,color:"#000000"}}>{moment().format("DD-MMM-YYYY")}</span>
                </div>
                <span style={webStyles.calendarWrapperDate}>{moment().format("dddd")}</span>
              </Box>
            </GridDate>
          </Grid>
        </Box>

        <GridBox container spacing={2}>
          <Grid
            item
            alignItems="center"
            lg={12}
          >
            <Box style={webStyles.addkapiwrapper}>
              
              {kpisDkrData.length === 0 ? (this.renderNoRecords())
              : (
                <Box>
                <Box display={'flex'} justifyContent={'space-between'}>
                <HeaderBox  display={'flex'}>
                  <Box style={{width:'17%',alignContent:'start'}}>KPI</Box>
                  <Box style={{width:'69%',alignContent:'start'}}>DKR Title</Box>
                  <InputHeadingBox>
                  <Box>DKR Input</Box>
                  <Box>Remarks</Box>
                  </InputHeadingBox>
                </HeaderBox>
              </Box>
             {this.renderDkrInput()}
            <ButtonBox>
                <div className="control kpi-report-btn" id="signupfieldcontrol2">
                  <button
                    type="button"
                    data-testId="saveDkrButtonTestID"
                    style={webStyles.saveCotinueBtn}
                    className="button submit"
                    value={this.state.dkrRemark}
                    onClick={this.savetKpiDKRData}
                  >
                    Save & Continue
                  </button>
                </div>
              </ButtonBox>
            </Box>
            )}
              
            </Box>
          </Grid>
       
        </GridBox>
      </Box>
       <Modal open={this.state.isModal}
       onClose={this.newHandleClose}
       data-testId="closeIconTestID"
       >
       <Box style={{
         position: 'absolute',
         top: '50%',
         left: '50%',
         transform: 'translate(-50%, -50%)',
         width: 400,
         backgroundColor: 'white',
         padding: '26px 38px',
         borderRadius: '8px',
       }}>
         <ModalHeaderBox >
           <Typography style={{
             fontFamily: 'Open Sans',
             fontSize: '18px',
             fontWeight: 700,
             color: '#000000'
           }}>{this.state.dkrName}</Typography>
           <CrossButton
             data-test-id="handleCloseId"
             onClick={this.newHandleClose}
           ><img src={cross} alt='X' /></CrossButton>
         </ModalHeaderBox>
         <StyledInputBox>
              <TextFieldBox
               data-testId="dkrInputRemarkTestID"
               multiline
               maxRows={5}
               minRows={5}
               variant="outlined"
               placeholder="Write Your Remark Here…"
               onChange={this.handleRemarkValue}
              />
            </StyledInputBox>
         <SubmitBoxContainer >
                <SubmitBox
                  data-testId="submitRemarkTestID"
                  onClick={this.handleRemarkSubmit}
                  style={{ cursor: 'pointer' }}>
                  <IAgreeTypography> Submit</IAgreeTypography></SubmitBox>
              </SubmitBoxContainer>
       </Box>
     </Modal>
     </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyles = {
    kpiTitle:{
      width:'16%',
      alignContent:'start',
      fontWeight:600,
      fontSize:"18px",
    },
    DKRContainer: {
     width : "100%"
    },
    DKRBox:{
      display: "flex",
      justifyContent: "space-between",
      margin:"10px 0px"
    },
    kpiDescription:{
      alignContent:'center',
      fontWeight:400,
      fontSize:"16px",
      lineHeight: "22px",
      fontFamily: "Open Sans",
      fontStyle: "italic",
      color:"#000000CC",
      margin: "5px 0px"
    },
    addkapiwrapper: {
      display: "flex",
      flexDirection: "column",
      marginLeft: "32px",
      marginRight: "32px"
    },
    imgDate :{
      display: "flex",
      alignItems: "center",
      gap: "15px"
    },
    
    calendarWrapperSpan: {
      fontSize: "14px",
      fontWeight: 400,
      fontFamily: "Open Sans",
    },

    calendarWrapperDate: {
      fontSize: "14px",
      fontWeight: 400,
      marginRight: "14px",
      fontFamily: "Open Sans",
      textTransform: "uppercase",
      color: "#000000",
    },
  
    calendarImg: {
      padding: "8px",
      borderRadius : "5px",
      backgroundColor: "#FFCC00"
    },
    modalContainer: {
      top: "50%",
      left: "50%",
      transform: `translate(-${"50%"}, -${"50%"})`, // Adjust the transform to center the modal
      position: "absolute",
      width: "375px",
      backgroundColor: "white",
      boxShadow: "rgba(0, 0, 0, 0.2) 0px 4px 8px",
      padding: "32px",
      borderRadius: "10px",
      overflowY: "scroll",
      height: "292px"
    },
    TextInput: {
      padding: "11px 16px",
      borderRadius: "8px",
      fontSize: "16px",
      lineHeight: "22px",
      fontWeight: 400,
      color: "rgba(0, 0, 0, 0.4)",
      border: "1px solid rgba(0, 0, 0, 0.1)",
    },
    selectBox:{
      padding: "11px 16px",
      borderRadius: "8px",
      color: "rgba(0, 0, 0, 0.4)",
      backgroundColor:'white'
    },
    maincontainer: {
      background: "#F3F4F5",
    },
    headerSection: {
      backgroundColor: '#F3F4F5',
      padding: '16px',
    },
    leftImgSection: {
  
    },
    bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)"
    },
    title: {
      fontSize: 14
    },
    pos: {
      marginBottom: 12
    },
    labelStyle: {
      color: "black",
      fontSize: "18px",
      fontWeight: "bold"
    },
    labelStyle1: {
      fontSize: "18px",
      lineHeight: '24px',
      color: '#000000'
    },
    inputField: {
      width: "90%",
      background: 'transparent',
      border: '0.5px solid #979797'
    },
    cotinueBtn: {
      margin: "20px 0 0",
      fontWeight: 600,
      height:"55px"
    },
    saveCotinueBtn:{
      width:'335px',
      margin: "20px 0 0",
      fontWeight: 600,
      height:"55px",
      cursor: "pointer"
    },
    marginLeft0: {
      marginLeft: 0,
      marginTop: 10
    },
    cancelBtn: {
      background: "transparent",
      margin: "10px 0 0"
    },
  
    buttonContainer: { display: "flex", gap: "20px",  },
    themeColor: {
      color: "#FDD100"
    },
    headerWithmandatory: {
      display: "flex",
      alignItems: "baseline",
      gap: "4px"
    },
    infIcon: { display: "flex", alignItems: "center", justifyContent: "center", border: "1px solid #FDD100", borderRadius: "70%", width: "30px", height: "30px", marginLeft: "10px" }
  }as any;
  const HeaderBox = styled(Box)({
    padding: "20px",
    width: "100%",
    fontSize: "18px",
    fontWeight: 600,
    color: "#000000",
    "@media (max-width: 990px)": {
      display: "none !important"
    },
  })
  const DkrTitleBox = styled(Box)({
    width:'65%',
    display:'flex',
    alignItems:'center',
    justifyContent: "start",
    gap:'10px',
  })
  const DkrTitleTypography = styled(Box)({
    fontWeight:400,
    fontSize:"18px",
    color:"#000000"
  })
  const ModalHeaderBox = styled(Box)({
    display: "flex",
    justifyContent: 'space-between',
    alignItems: 'center',
  })
  const CrossButton = styled(Button)({
    background: '#EBEBEB',
    padding: '11px',
    borderRadius: '6px',
    minWidth: '40px'
  })
  const SubmitBoxContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  })
  const SubmitBox = styled(Button)({
    width: '100%',
    background: '#FFCC00',
    padding: '14px',
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: '#FFCC00',
      color: '#000000',
      cursor: 'pointer',
    },
  })
  const IAgreeTypography = styled(Typography)({
    color: '#000000',
    fontWeight: 600,
    fontSize: '16px',
    cursor: 'pointer',
    lineHeight: '20px',
    textTransform:'none'
  })
  const StyledInputBox = styled(Box)({
    margin: '20px 0',
    width: '100%',
  });

  const TextFieldBox = styled(TextField)({
    width: '100%',
    borderRadius: '8px',
    height:"130px"
  });

const RemarkButtonBox = styled(Box)({
  textAlign: 'end',
  alignContent: 'center',
  marginLeft: "20px",
  cursor: "pointer",
  "& .remark_img":{
  width: "30px !important",
  hieght: "19px !important",
  filter: 'invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(0%) contrast(1)' 
  },
  "@media (max-width: 990px)": {
    marginLeft: "0px",
   },
});

const DkrBox = styled(Box)({
  display: "flex",
  justifyContent: "start",
  alignItems: "baseline",
  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  padding: "20px",
  width: "100%",
  fontSize: "18px",
  fontWeight: 600,
  color: "#000000",
  fontFamily: "Open Sans",
  borderRadius: "12px",
  margin: "10px 0px",
  "@media (max-width: 990px)": {
   flexDirection: "column",
  },
});
  
const InputBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between"
})

const InputHeadingBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "38%"
})

const InputContainer = styled(Box)({
  width: '35%',
  justifyContent: 'start',
  "& .textInput":{
    width : "430px",
    display: "block !important",
    [`@media (max-width: 1830px)`]: { width: "410px" },
    [`@media (max-width: 1750px)`]: { width: "380px" },
    [`@media (max-width: 1700px)`]: { width: "370px" },
    [`@media (max-width: 1650px)`]: { width: "360px" },
    [`@media (max-width: 1600px)`]: { width: "350px" },
    [`@media (max-width: 1550px)`]: { width: "310px" },
    [`@media (max-width: 1500px)`]: { width: "330px" },
    [`@media (max-width: 1400px)`]: { width: "290px" },
    [`@media (max-width: 1300px)`]: { width: "260px" },
    [`@media (max-width: 1250px)`]: { width: "230px" },
    [`@media (max-width: 1200px)`]: { width: "220px" },
    [`@media (max-width: 1100px)`]: { width: "210px" },
    [`@media (max-width: 1050px)`]: { width: "190px" },
    [`@media (max-width: 990px)`]: {
      width: "210px",
      display: "none !important",
    },
  }
})

const InputResponsiveBox = styled(Box)({
  justifyContent: 'start',
  "& .textInput": {
    display: "none !important",
    "@media (max-width: 990px)": {
      display: "block !important",
      width: "100% !important",
    },
  }
})

const KpiTitle =  styled(Box)({
  width: '16%',
  alignContent: 'start',
  fontWeight: 600,
  fontSize: "18px",
  "@media (max-width: 990px)": {
    width: "100% !important",
  },
})

const GridBox = styled(Grid)({
  width: "100% !important",
  "& .MuiGrid-item":{
    width: "100% !important",
  }
})

const ButtonBox = styled(Box)({
  gap: "20px", 
  "& .button":{
    width: "335px", 
  "@media (max-width: 990px)": {
    width: "100% !important",
  },

  }
})

const GridHead = styled(Grid)({
  padding: "19px 0px 21px 38px",
  alignItems: 'center',
  display: 'flex',
  "& .MuiGrid-item":{
      width: "100% !important",
  },
})

const GridDate = styled(Grid)({
  "@media (max-width: 990px)": {
    width: "100% !important",
  },
  "& .MuiGrid-item":{
      width: "100% !important",
  },
  "& .calendarWrapper":{
    display: "flex",
    justifyContent: "space-between",
    width: "298px",
    height: "62px",
    background: "#fff",
    alignItems: "center",
    padding: "15px 20px",
    borderRadius: "8px",
    "@media (max-width: 990px)": {
      width: "100% !important",
    },
  }

})
  
  // Customizable Area End