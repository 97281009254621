// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { setStorageData, getStorageData } from "../../../framework/src/Utilities";
import ToastNotification from "../../../components/src/ToastNotification.web";
import {DkrDetailsData} from '../../Gamification/src/AddDkrDetailsController';

export interface ValidResponseType {
  data: object;
  errors: Array<ErrorPayloadType>;
}
export interface ErrorPayloadType {
  key: string;
  message: string;
}
export interface ErrorResponseType {
  errors: string;
}
export interface InvalidResponseType {
  errors: Array<string>;
}

export interface DirectReportData {
    data: Array<DirectReport>
}

export interface ApproveResponseData {
  approval: Array<DirectReport>,
  error: string;
  message: string;
}

export interface DirectReport {
    id: "18",
    type: "direct_reportees",
    attributes: {
        id: string,
        name: string,
        designation_name: string,
        department_name: string,
        image: string,
        goal_percentage: string
    }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    kpiData?: DkrDetailsData;
    handleKpiData?: () => void;
    GoToDailySubmissions?: (keyData: string) => void;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    radius: number;
    profileRadius: number;
    progress: number;
    loading: boolean;
    directReportData: Array<DirectReport>;
    isProfileView: boolean;
    profileData: DirectReport
    open: boolean;
    newPointsAchieved: number;
    isValidationError: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class DirectReportController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getDirectReportApiCallId: string | null = null;
    submitApprovalPointApiKey: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationMessage),
            getName(MessageEnum.NavigationTargetMessage),
            getName(MessageEnum.NavigationPropsMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];

        this.state = {
            radius : 25,
            profileRadius : 50,
            progress: 50,
            loading: false,
            directReportData: [],
            isProfileView: false,
            profileData: {} as DirectReport,
            open: false,
            newPointsAchieved: 0,
            isValidationError: false
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        this.handleDirectReportData();
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (this.isValidResponseController(responseJson)) {
                this.apiSuccessCallBacksController(apiRequestCallId, responseJson);
            }
            if (this.isInValidResponseController(responseJson)) {
                this.apiFailureCallBacksController(apiRequestCallId, responseJson);
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start 
    isValidResponseController = (responseJson: ValidResponseType) => {
        return responseJson && !responseJson.errors;
    };

    isInValidResponseController = (responseJson: InvalidResponseType) => {
        return responseJson && responseJson.errors;
    };

    apiSuccessCallBacksController = (apiRequestCallId: string, responseJson: DirectReportData & ApproveResponseData) => {
        if (apiRequestCallId === this.getDirectReportApiCallId) {
            this.getDirectReportDataSuccessCallBack(responseJson)
        }
        if(apiRequestCallId === this.submitApprovalPointApiKey) {
          this.handleApprovalDataCallback(responseJson)
        }
    };

    handleApprovalDataCallback = (responseJson: ApproveResponseData) => { 
      this.props.handleKpiData && this.props.handleKpiData() 
      if(responseJson.approval) ToastNotification.showSuccess("Points approved successfully.");
      else if(responseJson.error) ToastNotification.showError(responseJson.error);
      
      this.setState({loading: false})
    }

    apiFailureCallBacksController = (apiRequestCallId: string, responseJson: InvalidResponseType) => {
        if (apiRequestCallId === this.getDirectReportApiCallId) {
            this.getDirectReportDataFailurCallBack(responseJson)
        }
    };

    apiCall = async (valueData: {
        contentType?: string;
        method?: string;
        endPoint?: string;
        body?: {};
        apiType?: string;
        type?: string;
      }) => {
        let { contentType, method, endPoint, body } = valueData;
        const token = (await getStorageData("authToken")) || "";
        let header = {
          "Content-Type": contentType,
          token,
        };
        let requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endPoint
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          method
        );
        body &&
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            body
          );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
      };
    // Customizable Area End

    // Customizable Area Start
    handleBackButton = () => {
        const toBack = new Message(getName(MessageEnum.NavigationMessage));
        toBack.addData(
            getName(MessageEnum.NavigationTargetMessage),
            "EmployeeDashboard"
        );
        toBack.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(toBack);
    };

    handleProfileView = (data : DirectReport) => {
        this.setState({ isProfileView: true , profileData : data })
    }

    goToKpiGameCard = () => {
        setStorageData("reporteeId", this.state.profileData.id)
        const kpiGameCard = new Message(getName(MessageEnum.NavigationMessage));
        kpiGameCard.addData(
            getName(MessageEnum.NavigationTargetMessage),
            "GamificationWeb"
        );
        kpiGameCard.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(kpiGameCard);
    };

    GoToDailySubmissions =()=>{
      this.props.GoToDailySubmissions && this.props.GoToDailySubmissions("DailySubmissions")
    };

    handleOpen = () => {
      this.setState({  open: true });
    };
  
    handleClose = () => {
      this.setState({ open: false });
    };

    handleSubmit = () => {
      this.setState({ open: false });
      this.handleKpiGamecardData();
    };
   
    handleInputChange = (event:  React.ChangeEvent<HTMLInputElement >)=>{
      const inputValue=event?.target.value
      const regex = /^-?\d+(\.\d+)?$/;
      if(!regex.test(inputValue) || ((parseInt(inputValue) > (this.props.kpiData?.weightage || 0)) || (parseInt(inputValue) < 0))) this.setState({isValidationError: true});
      else this.setState({isValidationError: false});
      this.setState({newPointsAchieved: parseInt(inputValue)})
    }

    checkInputValidation = () => {
      return this.state.isValidationError;
    }

    handleKpiGamecardData = async () => {
      this.setState({loading: true})
      this.submitApprovalPointApiKey = await this.apiCall({
        contentType: configJSON.validationApiContentType,
        method: configJSON.validationApiPUTMethodType,
        endPoint: `${configJSON.ApproveApiEndPoint}${this.props.kpiData?.submission_id}`,
        body: JSON.stringify({
          id: this.props.kpiData?.submission_id,
          point: this.state.newPointsAchieved,
          comment: "Approved by Divisional Admin for DA"
        })
      });
    };

    handleDirectReportData = async () => {
        this.setState({loading: true})
        this.getDirectReportApiCallId = await this.apiCall({
          contentType: configJSON.validationApiContentType,
          method: configJSON.validationApiMethodType,
          endPoint: configJSON.getDirectReportApiEndPoint,
        });
      };
   
     getDirectReportDataSuccessCallBack = (responseJson: DirectReportData) => {
        if(responseJson.data){
        this.setState({directReportData : responseJson.data, loading: false},)
        }
     }
   
     getDirectReportDataFailurCallBack = (responseJson: InvalidResponseType) => {
        const responses = responseJson.errors;
        if(responses){
          ToastNotification.showError(responseJson.errors[0]);
          this.setState({ loading: false})
        }
      }
    // Customizable Area End
}
