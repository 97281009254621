Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleAPiUpdate = "PUT";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Gamification";
exports.labelBodyText = "Gamification Body";

exports.btnExampleTitle = "CLICK ME";
exports.showTemplatesEndPoint="bx_block_gamification/templates"
exports.showKPIEndPoint="bx_block_gamification/kpis/"
exports.getKpiGamecardApiEndPoint="bx_block_gamification/kpis/kpi_with_dkr";
exports.postKpiDkrApiEndPoint="bx_block_gamification/comments";
exports.getCurrentProgressApiEndPoint="bx_block_gamification/comments/calculate_progress";
exports.getMonthWiseDetailApiEndPoint="bx_block_gamification/comments/month_wise_details";
exports.addPointsEndPoint="/bx_block_multilevelapproval/submissions";

exports.chooseDepartment="Choose Department";
exports.compulsory="*";
exports.allDepartments="All Departments";
exports.allTemplates="All Templates";
exports.noRecord="No Records Found !";
exports.subNoRecord="There are no records to display right now.";
exports.duplicate="Duplicate";
exports.confirmation="Are you really want to duplicate";
exports.yes="Yes";
exports.no="No";
// Customizable Area End