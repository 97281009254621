Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.validationApiPUTMethodType = "PUT";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "MultilevelApproval";
exports.labelBodyText = "MultilevelApproval Body";

exports.btnExampleTitle = "CLICK ME";
exports.getDirectReportApiEndPoint = "bx_block_gamification/direct_reportees";
exports.ApproveApiEndPoint = "/bx_block_multilevelapproval/submissions/"
// Customizable Area End