// Customizable Area Start
import React from 'react';
import {
    Box,
    styled,
    Button,
    Modal,
    TextField,
    Typography,
    InputAdornment,
} from '@material-ui/core';
import { cross, infoSquare } from "./assets";
import DirectReportController, { Props } from './DirectReportController.web';

// Customizable Area End


export default class Approval extends DirectReportController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderApprovalBox = () => {
        return (<>
             <ApprovalBox>
                <img className="approval_icon" src={infoSquare} />
                    <ApprovalContent data-testId="reportHeadingTestID">
                        {"Approval window is from 1"}<span className='first_number'>{"st"}</span>
                        {" to 5"}<span className='fifth_number'>{"th"}</span> {" of every month"}
                    </ApprovalContent>
            </ApprovalBox>
        </>)
    }

    renderViewDailySubmission = () => {
        return (<>
            <DailyButtonBox data-testId="toSubmissionTestID" onClick={this.GoToDailySubmissions}>
                          <DailyViewButton>{"View Daily Submissions"}</DailyViewButton>
            </DailyButtonBox>
        </>)
    }

    renderApprovePointButton = () => {
        return (<>
            {!this.props.kpiData?.da_approved_points && this.props.kpiData?.submission_id && <ApproveButtonBox data-testId="backIconTestID" onClick={this.handleOpen}>
                <ApproveBtnText >{"Approve Points"}</ApproveBtnText>
            </ApproveButtonBox>}
            <Modal open={this.state.open} onClose={this.handleClose}>
                <ModalBox>
                    <Box style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography style={{
                            fontSize: '18px',
                            fontWeight: 500,
                            color: '#000000',
                            lineHeight: '24px',
                        }}>Approve this month points for <span style={{ fontWeight: 700 }}>{this.props.kpiData?.KPI}</span></Typography>
                        <CrossButton onClick={this.handleClose}
                            data-testId="handleClose"><img src={cross} alt='X' /></CrossButton>
                    </Box>
                    <Typography style={{padding: '16px 0 0', color: '#000'}}>Points achieved: {this.props.kpiData?.employee_submitted_points} points</Typography>
                    <Box style={{ margin: '24px 0 20px 0' }}>
                        <TextField style={{ width: '100%' }}
                            label="Enter point*"
                            type="text"
                            variant="outlined"
                            onChange={this.handleInputChange}
                            data-testId="handleInputChangeId"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">Points</InputAdornment>
                            }}
                        />
                    </Box>
                    {this.checkInputValidation() && <Typography style={{ color: '#6191C7', fontSize: '13px', fontWeight: 700, marginBottom: '40px' }}>Points can be Min. 0 and Max. of Total Weightage of KPI</Typography>}
                    <SubmitBox disabled={(!this.state.newPointsAchieved || this.checkInputValidation())} onClick={this.handleSubmit} data-testId="handleCloseSubmitId"><SubmitTypography >Approve</SubmitTypography></SubmitBox>
                </ModalBox>
            </Modal>
        </>)
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
           {this.renderApprovalBox()}
           <div style={{display: 'flex', flexDirection: 'row', gap: '16px'}}>
            {this.renderApprovePointButton()}
            {this.renderViewDailySubmission()}
           </div>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const ApprovalBox = styled(Box)({
    width : "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "14px 0px",
    backgroundColor: "#F5F5F5",
    border:"0.5px solid #0000003E",
    boxShadow: "0px 1px 14px 0px #00000011",
    borderRadius: "8px",
    margin: "0 0 20px 0px",
    "& .approval_icon":{
        width: "18px",
        height: "18px",
        margin: "0px 10px"
    }
})

const ApprovalContent = styled(Box)({
    fontSize: '16px',
    fontWeight: 400,
    color: '#707070',
    lineHeight: '20px',
    "& .first_number":{ 
        position: "relative",  
        top: "-5px",  
        fontSize: "11px"
    },
    "& .fifth_number":{
        position: "relative",  
        top: "-5px",  
        fontSize: "11px"
    }
})

const DailyButtonBox = styled(Box)({
    display : "flex",
    alignItems: "center",
    margin: "20px 0px",
})

const DailyViewButton = styled(Box)({
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "20PX",
    color: '#6191C7',
    textDecoration: "underline",
    cursor: "pointer",
})

const ApproveButtonBox = styled(Button)({
    background: '#FFCC00',
    padding: '16px 77px 20px 78px',
    borderRadius: '8px',
    textTransform: 'unset',
    cursor: 'pointer',
    margin: "20px 0px",
    '&:hover': {
        backgroundColor: '#FFCC00',
        color: '#000000',
        cursor: 'pointer',
    },
    boxShadow: 'unset'
})

const ApproveBtnText = styled(Box)({
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '20px',
    color: '#000000'
})


const ModalBox = styled(Box)({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '350px',
    backgroundColor: 'white',
    padding: '24px 20px',
    borderRadius: '8px',
    '& .MuiButton-root:hover': {
        background: '#FFCC00'
    }
})
const SubmitBox = styled(Button)({
    width: '100%',
    background: '#FFCC00',
    padding: '14px',
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: '8px',
})
const CrossButton = styled(Button)({
    background: '#EBEBEB', padding: '11px', borderRadius: '6px',
    minWidth: '40px'
})

const SubmitTypography = styled(Typography)({
    color: '#000000', fontWeight: 600, fontSize: '16px', cursor: 'pointer',
    textTransform: 'none'
})

// Customizable Area End 