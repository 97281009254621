// Customizable Area Start
import React from 'react';
import {
    Box,
    Typography,
    Paper,
    styled,
    Avatar,
    Grid
} from '@material-ui/core';
import { arrow_left, arrow_right, addbadgeIcon, messageIcon, thumbGreenIcon, infoSquare } from "./assets";
import Loader from "../../../components/src/Loader";
import DirectReportController, { Props } from './DirectReportController.web';
// Customizable Area End


export default class DirectReport extends DirectReportController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderHeaderSection = () => {
        return (<>
            <HeaderSection>
                <BackIconBox>
                    <img data-testId="backIconTestID" onClick={this.handleBackButton} className='backIcon' src={arrow_left} alt="<" />
                </BackIconBox>
                <ReportHeadingText data-testId="reportHeadingTestID">{"Direct Reportees"}</ReportHeadingText>
            </HeaderSection>
        </>)
    }

    renderEmployeeHeader = () => {
        return (<>
            <HeaderSection>
                <ReportHeadingText data-testId="employeProfileTestID">{"Employee Profile"}</ReportHeadingText>
            </HeaderSection>
        </>)
    }

    renderReporteesList = () => {
        const { radius, progress, directReportData, loading } = this.state;
        const circumference = 2 * Math.PI * radius;
        const strokeDashoffset = circumference - (progress / 100) * circumference;
        return (<>
           <Loader loading={loading} />
           {this.renderHeaderSection()}
            <ReporteesContainer>
            {directReportData.map((data,index)=>(
                <ReporteesBox key={index} data-testId="profileBoxTestID" onClick={()=>this.handleProfileView(data)}>
                    <ProfileBox>
                        <ProfileImgBox>
                            <Avatar className='profileIcon' alt={data.attributes.name} src={data.attributes.image} />
                        </ProfileImgBox>
                        <ProfileNameText>{data.attributes.name}</ProfileNameText>
                    </ProfileBox>
                    <ContentBox>{data.attributes.designation_name}</ContentBox>
                    <ProgressBox>
                        <ProgressCircleWrapper>
                            <svg width="80" height="80">
                                <CircleBackground r={radius} cx="40" cy="40" />
                                <CircleProgress r={radius} cy="40" cx="40"
                                    strokeDashoffset={strokeDashoffset}
                                    strokeDasharray={circumference}
                                />
                            </svg>
                            <ProgressText>{progress}%</ProgressText>
                        </ProgressCircleWrapper>
                    </ProgressBox>
                </ReporteesBox>
            ))}
            </ReporteesContainer>
        </>)
    }

    renderEmployeeProfile = () => {
        const { profileRadius, progress, profileData } = this.state;
        const circumference = 2 * Math.PI * profileRadius;
        const strokeDashoffset = circumference - (progress / 100) * circumference;
        return (<>
           {this.renderEmployeeHeader()}
            <ProfileContainer>
                <ProfilesBox >
                    <ProfileBox>
                        <ProfileImgBox>
                            <Avatar className='employeeprofileIcon' alt={profileData.attributes.name} src={profileData.attributes.image} />
                        </ProfileImgBox>
                        <EmployeeBox>
                        <EmployeeProfileNameText>{profileData.attributes.name}</EmployeeProfileNameText>
                        <EmployeeDesignationName>{profileData.attributes.designation_name}</EmployeeDesignationName>
                        </EmployeeBox>
                    </ProfileBox>
                    <AddBadgeBox>
                        <BadgeButtonBox>
                          <BadgeButton>{"Add Badge"}</BadgeButton>
                          <img className="badge_button_img" src={addbadgeIcon}/>
                        </BadgeButtonBox>
                        <img className="message_Icon" src={messageIcon}/>
                    </AddBadgeBox>
                </ProfilesBox>
            <MonthDateBox>
                <MonthBox>
                    <img className='left_icon' src={arrow_left} alt="<" />
                    <MonthContent>{"March 2024"}</MonthContent>
                    <img className='right_icon' src={arrow_right} alt=">" />
                </MonthBox>
                <DateBox>{"Assigned on: 01-03-2024"}</DateBox>
            </MonthDateBox>
                <DescriptionSection >
                    <ProgressTitleBox>
                    <KpiTitle>{"Progress Percentage"}</KpiTitle>
                    <img className='thump_icon' src={thumbGreenIcon} alt=">" />
                    </ProgressTitleBox>
                    <Grid container spacing={2} justifyContent={"space-between"} alignItems={"flex-end"}>
                        <Grid item xs={6} md={3}>
                            <PointsSection>
                                <TypographyStyles>{"Total no. of KPIs"}<span className='kpi_number'>{"11"}</span></TypographyStyles>
                                <BadgeButtonBox data-testId="kpisButtonTestID" onClick={this.goToKpiGameCard}>
                                    <BadgeButton data-testId="reviewKpisTestID">{"Review KPIs"}</BadgeButton>
                                    <img className="badge_button_img" src={addbadgeIcon} />
                                </BadgeButtonBox>
                            </PointsSection>
                        </Grid>
                        <Grid item xs={6} md={3}>
                        <ProgressBox>
                        <ProgressCircleWrapper>
                            <svg width="120" height="120">
                                <CircleBackground r={profileRadius} cx="60" cy="60" />
                                <CircleProgress r={profileRadius} cy="60" cx="60"
                                    strokeDashoffset={strokeDashoffset}
                                    strokeDasharray={circumference}
                                />
                            </svg>
                            <EmpProgressText>{progress}%</EmpProgressText>
                        </ProgressCircleWrapper>
                    </ProgressBox> 
                        </Grid>
                            <Grid item xs={6} md={3}>
                                <WeightageSection>
                                    <WeightageTypography>{"Points achieved"}</WeightageTypography>
                                    <PointsTypography>{"80 Points"}</PointsTypography>
                                </WeightageSection>
                            </Grid>
                        <Grid item xs={6} md={3}>
                            <GoalSection>
                                <WeightageTypography>{"Points approved"}</WeightageTypography>
                                <PointsTypography>{"80 Points"}</PointsTypography>
                            </GoalSection>
                        </Grid>
                    </Grid>
                </DescriptionSection>
                <ApprovalBox>
                <img className="approval_icon" src={infoSquare} />
                    <ApprovalContent>
                        {"Approval window is from 1"}<span className='first_number'>{"st"}</span>
                        {" to 5"}<span className='fifth_number'>{"th"}</span> {" of every month"}
                    </ApprovalContent>
                </ApprovalBox>
            </ProfileContainer>
        </>)
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                {this.state.isProfileView ?
                    this.renderEmployeeProfile()
                    : this.renderReporteesList()}
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const HeaderSection = styled(Paper)({
  padding: '20px 40px 20px 32px',
  height: '64px',
  fontFamily: 'Open Sans',
  marginBottom: '20px',
  background: '#F3F4F5',
  display: 'flex',
  alignItems: 'center',
  gap: '21px',
});

const BackIconBox = styled(Box)({
    cursor: "pointer",
    "& .backIcon": {
        height: '14px',
        width: '7px'
    }
})

const ReportHeadingText = styled(Typography)({
    fontFamily: 'Open Sans',
    fontSize: '18px',
    fontWeight: 400,
    color: '#000000',
    lineHeight: '24px',
})

const ReporteesContainer = styled(Box)({
   width : "100%",
   padding : "10px 30px"
})

const ReporteesBox = styled(Box)({
    width : "100%",
    borderRadius: "12px",
    backgroundColor: "#FFFFFF",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "15px 30px",
    cursor: "pointer",
 })

const ProfileBox = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
})

const ProfileImgBox = styled(Box)({
    cursor: "pointer",
    alignItems: "center",
    "& .profileIcon": {
        height: '50px',
        width: '50px',
        borderRadius: "50%"
    },
    "& .employeeprofileIcon": {
        height: '106px',
        width: '106px',
        borderRadius: "50%"
    }
})

const ProfileNameText = styled(Box)({
    fontFamily: "Open Sans",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "20px",
    color: '#000000',
    marginLeft: "20px"
})

const ContentBox = styled(Box)({
    fontFamily: "Open Sans",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "20px",
    color: '#000000',
})

const ProgressBox = styled(Box)({
})

const ProgressCircleWrapper = styled(Box)({
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .progressImg":{
        position: 'absolute',
        top: '-8px',
        left: '50px',
    }
});

const CircleBackground = styled("circle")({
    fill: "none",
    stroke: "#ECECEC",
    width: '80px',
    strokeWidth: 10,
    transform: "rotate(-90deg)",
    transformOrigin: "50% 50%",
});

const CircleProgress = styled("circle")({
    fill: "none",
    width: '80px',
    stroke: "#F7B500", 
    strokeWidth: 10,
    strokeLinecap: "round",
    transition: "stroke-dashoffset 2s ease",
    transform: "rotate(-90deg)",
    transformOrigin: "50% 50%",
    animation: "animateProgress 2s forwards",
});

const ProgressText = styled(Typography)({
    position: "absolute",
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "100%",
    color: "#000000",
});

const EmpProgressText = styled(Typography)({
    position: "absolute",
    fontFamily: "Open Sans",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "100%",
    color: "#000000",
});

const ProfileContainer = styled(Box)({
    width : "100%",
    padding : "10px 30px"
 })

const ProfilesBox = styled(Box)({
    width: "100%",
    borderRadius: "12px",
    backgroundColor: "#FFFFFF",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "15px 30px",
})

const EmployeeBox = styled(Box)({
    marginLeft: "20px",
})

const EmployeeProfileNameText = styled(Box)({
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: "22px",
    lineHeight: "20px",
    color: '#000000',
    margin: "8px 0px"
})

const EmployeeDesignationName = styled(Box)({
    fontFamily: "Open Sans",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "16PX",
    color: '#1CA0FF',
    margin: "8px 0px"
})

const AddBadgeBox = styled(Box)({
    display : "flex",
    alignItems: "center",
    "& .message_Icon":{
        width: "32px",
        height: "32px",
        marginLeft: "30px"
    }
})

const BadgeButtonBox = styled(Box)({
    display : "flex",
    alignItems: "center",
    "& .badge_button_img":{
        width: "16px",
        height: "20px",
        marginLeft: "5px",
        cursor: "pointer"
    }
})

const BadgeButton = styled(Box)({
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "20PX",
    color: '#000000',
    textDecoration: "underline",
    cursor: "pointer"
})

const MonthDateBox = styled(Box)({
  display: 'flex',
  alignItems: "center", 
  justifyContent: "space-between", 
  marginTop: '35px',
  marginBottom: '24px',
})

const MonthBox = styled(Box)({
  display: "flex", 
  gap: '32px', 
  alignItems: 'center',
  "& .left_icon":{
    height: '14px',
    width: '10px' 
  },
  "& .right_icon":{
    height: '14px',
    width: '10px' 
  },
})

const MonthContent = styled(Typography)({
    fontFamily: 'Open Sans',
    fontSize: '20px',
    fontWeight: 600,
    color: '#000000',
    lineHeight: '20px'
})

const DateBox = styled(Typography)({
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 700,
    color: '#000000',
})

const DescriptionSection = styled(Paper)({
    border: '0.5px solid #FFCC00',
    marginBottom: '25px',
    borderRadius: '8px',
    background: '#FFFDF6',
    padding: '32px 20px 20px 20px',
    boxShadow: '0px 1px 14px 0px #00000011',
});
const KpiTitle = styled(Typography)({
    marginBottom: '33px',
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '20px',
    color: '#000000'
});

const PointsSection = styled(Paper)({
    paddingBottom: '10px',
});

const WeightageSection = styled(Paper)({
    backgroundColor: '#FFF9E1',
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    border: '1px solid #FFCC00',
    borderRadius: '8px',
    fontFamily: 'Open Sans',
});
const GoalSection = styled(Paper)({
    backgroundColor: '#FFFDF6',
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    background: 'linear-gradient(180deg, #FFEDA6 0%, #FFDD55 100%)',
    borderRadius: '8px',
    fontFamily: 'Open Sans',
});

const TypographyStyles = styled(Box)({
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    color: '#000000',
    marginBottom: "10px",
    "& .kpi_number":{
     marginLeft: "30px"
    }
});

const WeightageTypography = styled(Typography)({
    fontSize: '16px',
    fontWeight: 500,
    color: '#000000',
    lineHeight: '100%',
})
const PointsTypography = styled(Typography)({
    fontSize: '24px',
    fontWeight: 600,
    color: '#000000',
    lineHeight: '43px'
})

const ProgressTitleBox = styled(Box)({
    width : "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    "& .thump_icon":{
        width: "60px",
        height: "60px",
    }
})

const ApprovalBox = styled(Box)({
    width : "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "14px 0px",
    backgroundColor: "#F5F5F5",
    border:"0.5px solid #0000003E",
    boxShadow: "0px 1px 14px 0px #00000011",
    borderRadius: "8px",
    "& .approval_icon":{
        width: "18px",
        height: "18px",
        margin: "0px 10px"
    }
})

const ApprovalContent = styled(Box)({
    fontSize: '16px',
    fontWeight: 400,
    color: '#707070',
    lineHeight: '20px',
    "& .first_number":{ 
        position: "relative",  
        top: "-5px",  
        fontSize: "11px"
    },
    "& .fifth_number":{
        position: "relative",  
        top: "-5px",  
        fontSize: "11px"
    }
})

// Customizable Area End 