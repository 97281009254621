import React, { Component } from "react";
import toast from "react-hot-toast";

type ToastProps = {
  message: string;
  type: "success" | "error";
};

class ToastNotification extends Component {
  static showToast({ message, type }: ToastProps) {
    const backgroundColor = type === "success" ? "#4CAF50" : "#F44336";

    toast.custom((t) => (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          background: backgroundColor,
          color: "#fff",
          padding: "10px 15px",
          borderRadius: "5px",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          minWidth: "250px",
        }}
      >
         {message}
        <button
          onClick={() => toast.dismiss(t.id)}
          style={{
            marginLeft: "10px",
            background: "transparent",
            border: "none",
            color: "#fff",
            cursor: "pointer",
            fontSize: "16px",
          }}
        >
          ✖
        </button>
      </div>
    ), { duration: 10000, position: "top-right" });
  }

  static showSuccess(message: string) {
    this.showToast({ message, type: "success" });
  }

  static showError(message: string) {
    this.showToast({ message, type: "error" });
  }

  static dismissToast() {
    toast.dismiss();
  }
}

export default ToastNotification;

