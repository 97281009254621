import React, { Component } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, Box } from '@material-ui/core';
import {Close} from '@material-ui/icons';
import { withStyles, WithStyles } from '@material-ui/core/styles';

// Define styles using withStyles HOC
const styles = {
  root: {
    maxWidth: 490,
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '20px',
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  dialogContent: {
    fontSize: 16,
    fontWeight: 400,
    color: 'black',
  },
  dialogActions: {
    justifyContent: 'center',
  },
  actionButtons: {
    display: 'flex',
    gap: '20px',
  },
  cancelButton: {
    padding: '8px 20px',
    borderRadius: '4px',
    backgroundColor: '#f0f0f0',
  },
  confirmButton: {
    padding: '8px 20px',
    borderRadius: '4px',
    backgroundColor: '#3f51b5',
    color: 'white',
  },
};

// Define the type of props that the class component will accept
interface ReusableDialogProps extends WithStyles<typeof styles> {
  open: boolean;
  onClose: () => void;
  title: string;
  description: [];
}

class ReusableDialog extends Component<ReusableDialogProps> {
  render() {
    const { open, onClose, title, description, classes } = this.props;

    return (
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        BackdropProps={{
          style: { backgroundColor: 'rgba(0, 0, 0, 0.3)' },
        }}
        PaperProps={{
          style: { boxShadow: 'none' },
          elevation: 0,
        }}
      >
        <Box className={classes.root}>
          <Box id="dialog-title" className={classes.dialogTitle}>
            <Typography variant="h6" style={{ fontWeight: 500, color: "red", marginLeft: "30px" }}>
              {title}
            </Typography>
            <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
              <Close onClick={onClose}/>
            </IconButton>
          </Box>
          <DialogContent>
            <Typography id="dialog-description" className={classes.dialogContent}>
              {description && description.length > 1 ? (
                <ul>
                  {Array.isArray(description) && description.map((errMsg, index) => (
                    <li key={index}>{errMsg}</li>
                  ))}
                </ul>
              ):description}
            </Typography>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
          </DialogActions>
        </Box>
      </Dialog>
    );
  }
}

export default withStyles(styles)(ReusableDialog);
