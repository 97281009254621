import React from "react";
// Customizable Area Start
import { createTheme, styled } from "@material-ui/core/styles";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import {
  Box,
  Button,
  Typography,
  TableBody, Modal, TableCell, MenuItem, Checkbox, TableRow, TableContainer, Table, TableHead, Link, IconButton, Divider, CircularProgress
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';

// Customizable Area End
import TemplateAddedController, { Props, TemplateKpis} from "./TemplateAddedController";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff"
    }
  },
  typography: {
    h6: {
      fontWeight: 500
    },
    subtitle1: {
      margin: "20px 0px"
    }
  }
});
export default class TemplateAdded extends TemplateAddedController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  tagModal() {
    return (
      <Modal open={this.state.tagTemplateModal} >
        <ModalBox>
          <ModalTitleBoxNew>
            <HeadingNewTypography>Tag Template</HeadingNewTypography>
          </ModalTitleBoxNew>
          <ContentBox data-test-id="ContextBoxId" >

            <Box>
              <ContentNewTypography>
                Template Name
              </ContentNewTypography>
              <StyledBox >
                <input
                  value={this.state.tagTemplateName}
                  disabled
                  style={{
                    padding: '16px',
                    width: '100%',
                    height: '56px',
                    borderRadius: "12px",
                    border: "0.25px solid #979797",
                    background: "#F3F4F5", fontSize: "16px",
                    fontWeight: 500,
                    color: "#959595"
                  }}></input>
              </StyledBox>
            </Box>

            <Box>
              <ContentNewTypography>
                Department
              </ContentNewTypography>
              <Box>
                <input style={{
                  width: '100%',
                  height: '56px',
                  borderRadius: "12px",
                  border: "0.25px solid #979797",
                  background: "#F3F4F5",
                  fontSize: "16px",
                  fontWeight: 500,
                  color: "#959595",
                  padding: '16px'
                }}
                  disabled
                  value={this.state.tagTemplateDepartment}
                ></input>
              </Box>
            </Box>
            <Box><ContentNewTypography>
              Employees
            </ContentNewTypography>
              <Box>
                <NewStyledModalContainer style={{ width: "100%" }}>
                  <div ref={this.dropdown}>
                    <Box
                      onClick={this.toggleDropdown}
                      data-test-id="dropdown-trigger"
                      style={{
                        width: '100%',
                        height: "56px",
                        backgroundColor: "#F6F6F9",
                        borderRadius: "12px",
                        padding: "15px 8px 15px 15px",
                        cursor: "pointer",
                        position: "relative",
                        background: "#F3F4F5",
                        border: '0.25px solid #979797',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '15px',
                      }}
                    >
                      {this.state.EmployeeName.length === 0 ? (
                        <NewStyledSpan>{'Select user'}</NewStyledSpan>
                      ) : (
                        <>
                          {this.state.EmployeeName.map((name: any, index: any) => (
                            <Box
                              key={`employee-${index}`}
                              onClick={(e) => this.handleStopPropagation(e)}
                              data-test-id="eventMockId"
                              style={{
                                background: '#D8D8D8',
                                display: 'flex',
                                padding: '3px 7px',
                                borderRadius: '12px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '8px',
                                flexWrap: 'wrap',
                                width: 'fit-content',
                              }}
                            >
                              <NewStyledSpan>{name}</NewStyledSpan>
                              <CancelOutlinedIcon
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                  this.handleSelectionTemplate({
                                    id: this.state.selectedEmployeeCheckBox[index],
                                    name: name,
                                  })
                                }
                              />
                            </Box>
                          ))}
                        </>
                      )}
                      <StyledExpandMoreIcon />

                      {this.state.isSelectOpen && (
                        <Box
                          onClick={(e) => this.handleStopPropagation(e)}
                          data-test-id="dropdown-menu"
                          style={{
                            position: "absolute",
                            top: "100%",
                            left: "0",
                            width: "100%",
                            overflowY: "auto",
                            backgroundColor: "white",
                            borderRadius: "12px",
                            zIndex: 1,
                            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                            paddingBottom: "10px",
                            marginTop: "5px",
                            maxHeight: "188px",
                          }}
                        >
                          {(this.state.alreadyAssigned.length > 0 || this.state.accountAvailable.length > 0) ? (
                            <>
                              {this.state.accountAvailable.map((val: any, index: any) => (
                                <MenuItem
                                  key={`accountAvailable-${index}`}
                                  value={val.id}
                                  data-testid="handleSelectionTemplateanti"
                                  onClick={() => this.handleSelectionTemplate(val)}
                                  style={{
                                    fontSize: "16px",
                                    fontFamily: "Open Sans",
                                    fontWeight: 400,
                                    color: "black",
                                   paddingLeft:"0px",
                                    maxWidth: "100%",
                                    borderRadius: "8px",
                                    whiteSpace: "normal",
                                    overflowWrap: "break-word",
                                   
                                    display: 'flex',
                                    gap: '16px',
                                    marginBottom: '-8px',
                                  }}
                                  className="showTemplateName"
                                >
                                  <Checkbox
                                    style={{
                                      fontSize: 'large',
                                      paddingRight: "0px",
                                      color: "#FFD11A",
                                    }}
                                    checked={this.state.selectedEmployeeCheckBox.includes(val.id)}
                                  />
                                  <NamesTypography>{val.name}</NamesTypography>
                                </MenuItem>
                              ))}
                              {this.state.alreadyAssigned.map((val: any, index: any) => (
                                <MenuItem
                                  key={`alreadyAssigned-${index}`} 
                                  value={val.id}
                                  data-testid="handleSelectionTemplateanti"
                                  style={{
                                    fontSize: "16px",
                                    fontFamily: "Open Sans",
                                    fontWeight: 400,
                                    color: "black",
                                    paddingLeft:"0px",
                                    maxWidth: "100%",
                                    borderRadius: "8px",
                                    whiteSpace: "normal",
                                    overflowWrap: "break-word",
                                    
                                    display: 'flex',
                                    gap: '16px',
                                    marginBottom: '-8px',
                                  }}
                                  className="showTemplateName"
                                >
                                  <Checkbox
                                    style={{
                                      fontSize: 'large',
                                      paddingRight: "0px",
                                      color: "#DADADA",
                                    }}
                                    checked={this.state.alreadyAssigned.length > 0}
                                  />
                                  <NamesTypography style={{ color: 'gray' }}>{val.name}</NamesTypography>
                                </MenuItem>
                              ))}
                            </>
                          ) : (
                            <MenuItem
                              disabled
                              style={{
                                fontSize: "16px",
                                fontFamily: "Open Sans",
                                fontWeight: 400,
                                color: "#D32F2F",
                              
                                maxWidth: "290px",
                                borderRadius: "8px",
                                whiteSpace: "normal",
                                overflowWrap: "break-word",
                              
                                display: 'flex',
                                gap: '16px',
                                marginBottom: '-8px',
                              }}
                            >
                              <NoTemplatesTypography>No Employees Found</NoTemplatesTypography>
                            </MenuItem>
                          )}
                        </Box>
                      )}
                    </Box>

                    <StyledSpan>{this.state.errorMessage}</StyledSpan>
                  </div>
                </NewStyledModalContainer>
              </Box>
            </Box>
          </ContentBox>
          <SubmitBoxNewContainer >
            <SubmitBoxContainer
              data-test-id="handleCloseSubmitId"
              onClick={this.saveTemplateModal}
              style={{ cursor: 'pointer', fontSize: '16px', fontWeight: 600, color: "#000000", textTransform: 'capitalize' }}>
              Save
            </SubmitBoxContainer>
            <SubmitBoxContainer
              data-test-id="handleTemplateCancel"
              onClick={this.handleTagTemplateClose}
              style={{ cursor: 'pointer', fontSize: '16px', textTransform: 'capitalize', fontWeight: 600, color: "#000000", background: 'white', border: '1px solid #979797' }}>
              Cancel
            </SubmitBoxContainer>
          </SubmitBoxNewContainer>
        </ModalBox>
      </Modal>
    )
  }

  render() {
    // Customizable Area Start

    const breadcrumbs = [
      <Link
        key="1"
        href="/TemplateAdded" >
        <TemplateAddedTypography >Template Added</TemplateAddedTypography>
      </Link>,
    ];

    const CustomTextField = styled(Table)({
      "& .MuiTableCell-root": {
        borderBottom: "none",
      },
      '@media (max-width: 992px)': {
        '& .table-parent': {
          width: 'calc(100vw - 112px)', overflow: 'auto',
        },
        '& .table-inner-parent': {
          minWidth: 1000
        }
      }
    });

    // Customizable Area End

    return (
      // Customizable Area Start
      <>
        <StyledForm >
          <div style={webStyle.filterHeader}>
            <Breadcrumbs separator="" aria-label="breadcrumb">
              <div>
                <StyledIconButton  >
                  <Link href="/Template" style={{ color: "black" }}> <ArrowBackIosIcon /></Link>
                </StyledIconButton>
              </div>
              {breadcrumbs}
            </Breadcrumbs>
          </div>
        </StyledForm>
        {this.state.circularLoading ? (
          <CircularProgressBox>
            <StyledCircularProgress />
          </CircularProgressBox>
        ) : (<Box style={{ ...webStyle.MainContainer, marginBottom: "10%" }}>
          {Array.isArray(this.state.templateAdd) && (
            this.state.templateAdd.map((template: any, index: any) => (
              <StyledNameDesBox >
                <StyledDecription >
                  <Typography style={webStyle.Typography1}>{template.attributes.name}</Typography>
                  <Typography style={webStyle.Typography2}>{template.attributes.description} </Typography>
                </StyledDecription>
                <Box >
                  <StyledButtonBox >
                    < EditTemplateButton style={{
                      fontFamily: 'Open Sans',
                      backgroundColor: "FDD100",
                      color: "black",
                      minWidth: "145px",
                      fontSize: "medium",
                      fontWeight: 550,
                      textTransform: "none",
                    }}
                      data-test-id="nextButton"
                      onClick={this.navigateToEditTemplate2}
                    >
                      {this.getButtonText(template)}
                    </EditTemplateButton>
                    <Button style={{
                      fontFamily: 'Open Sans',
                      backgroundColor: "FDD100",
                      color: "black",
                      minWidth: "145px",
                      fontSize: "medium",
                      fontWeight: 550,
                      textTransform: "none",
                      display: template.attributes.status === "approved" ? "none" : "block"
                    }}
                      onClick={this.tagTemplateFunction}
                    >
                      Tag Template
                    </Button>
                  </StyledButtonBox>
                </Box>
              </StyledNameDesBox>
            )))}
          <TableBox  >
            <CustomTextField>
              <TableContainer className="table-parent" style={{ marginLeft: "10px" }}>
                <Table className="table-inner-parent" aria-label="kpi table" style={{ borderTop: "1px solid rgba(224, 224, 224, 1)", borderLeft: "1px solid rgba(224, 224, 224, 1)" }}>
                  <TableHead style={{ borderBottom: "1px solid rgba(224, 224, 224, 1)" }}>
                    <TableRow>
                      <TableCell style={webStyle.TableCell1}>Points</TableCell>
                      <TableCell style={webStyle.TableCell1}>KPI</TableCell>
                      <TableCell style={webStyle.TableCell2}>Details</TableCell>
                    </TableRow>
                  </TableHead>


                  <TableBody style={{ borderTop: "1px solid rgba(224, 224, 224, 1) !important" }}>
                    {
                      this.state.templatekpisData.map((kpi: TemplateKpis, kpiIndex: number) => (
                        <TableRow key={kpiIndex} style={{ marginBottom: "10px" }}>
                        <TableCell style={{ paddingLeft: "0px" }}>
                          <Box
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "240px",
                              }}
                          >
                            <Divider
                                style={{ flex: 1, borderColor: "rgba(224, 224, 224, 1)" }}
                            />
                            <StyledModalContainer>
                              <Box>
                                <p>{kpi.weightage || "N/A"}</p>
                              </Box>
                            </StyledModalContainer>
                            <Divider
                                style={{ flex: 1, borderColor: "rgba(224, 224, 224, 1)" }}
                            />
                          </Box>
                          </TableCell> 
                        <TableCell>
                          <Button
                              variant="contained"
                              style={{
                                paddingBottom: "2px",
                                backgroundColor: "#FDD100",
                                color: "black",
                                width: "180px",
                                borderRadius: "10px",
                                fontWeight: 700,
                                border: '1px dotted #707070'
                              }}
                          >
                            {kpi.name || "No Name"}
                          </Button>
                        </TableCell>

                        <TableCell className="showTempateName" >
                           <Typography
                              style={{
                                color: "Black",
                                lineHeight: 1.5,
                                maxHeight: "113px",
                                overflow: "overlay",
                                backgroundColor: "#F3F4F5",
                                borderRadius: "10px",
                                padding: "12px 15px 12px 12px",
                              }}
                          >
                            {kpi.description || "No Description"}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))
                }
                </TableBody>
                </Table>
              </TableContainer>
            </CustomTextField>
          </TableBox>
        </Box>)}
        {this.tagModal()}

      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {
  MainContainer: {
    cursor: "pointer", margin: "20px", borderRadius: "10px"
  },
  Typography1: {
    color: 'black', fontSize: "18px", fontWeight: 600, marginBottom: '10px',
  },
  Typography2: {
    color: 'black', fontSize: "14px", lineHeight: '20px'
  },
  Typography3: {
    color: 'black', fontSize: "14px", lineHeight: 0.5
  },
  TableCell1: {
    fontFamily: 'Open Sans', color: "black", fontSize: "18px", fontWeight: 550, paddingLeft: "100px", width: "17%"
  },
  TableCell2: {
    fontFamily: 'Open Sans', color: "black", fontSize: "18px", fontWeight: 550, display: "flex", justifyContent: "center",
  },
  TableCell3: {
    paddingLeft: "0px !important"
  },
 

  filterHeader: {
    padding: "0px 0px 0px 22px",
    display: "flex",
    alignItems: "flex-start",
    gap: "20px"
  },
  template: {

  }

};


const StyledModalContainer = styled(Box)({
})

const TemplateAddedTypography = styled(Typography)({
  color: 'black',
  fontSize: "18px",
  fontWeight: 700,
  fontFamily: 'Open Sans'
})
const StyledIconButton = styled(IconButton)({
  color: "black",
  fontSize: "small",
  padding: "0px",
  background: "none"
})
const StyledDecription = styled(Box)({
  width: "60%",
  '@media (max-width: 890px)': {
    width: '100%',
  },

})
const StyledButtonBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  gap: '20px',
  alignItems: "center",
  marginTop: "25px",
  flexWrap: "wrap",
  '@media (max-width: 890px)': {
    gap: "10px",
  },
});
const EditTemplateButton = styled(Button)({
})
const CircularProgressBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '400px'
})
const StyledCircularProgress = styled(CircularProgress)({
  color: '#FDD001'
})
const StyledNameDesBox = styled(Box)({
  justifyContent: 'space-between',
  display: 'flex',
  flexWrap: 'wrap',
  padding: "15px 15px 15px 10px",
  marginBottom: "20px",
});
const StyledForm = styled('form')({
  display: "flex",
  flexWrap: "wrap",
  padding: "10px 10px",
  backgroundColor: '#F3F4F5',
  position: "relative",
  height: "72px",
  alignItems: "center",
  '@media (max-width: 576px)': {
    marginTop: '35px',
  },
});



const ModalBox = styled(Box)({
  position: 'fixed',
  padding: '55px 48px 55px 48px',
  maxWidth: '725px',
  width: '100%',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'white',
  borderRadius: '8px',

});

const ModalTitleBoxNew = styled(Box)({
  display: "flex",
  justifyContent:
    'space-between',
  position: 'relative'
})
const ContentBox = styled(Box)({
})
const ContentNewTypography = styled(Typography)({
  color: '#000000',
  fontSize: '18px',
  fontWeight: 700,
  lineHeight: '24px',
  textAlign: 'left',
  marginTop: "35px",
  marginBottom: "16px",

})
const SubmitBoxNewContainer = styled(Box)({
  display: 'flex',
  width: '100%', paddingRight: '51px',
  gap: 14
})
const HeadingNewTypography = styled(Typography)({
  fontSize: "24px",
  fontWeight: 700,
  lineHeight: '24px',
  textAlign: 'left',
  color: '#000000'


})
const SubmitBoxContainer = styled(Button)({
  maxWidth: '100px',
  width: '100%',
  background: '#FFCC00',
  padding: '10px',
  display: 'flex',
  justifyContent: 'center',
  cursor: 'pointer',
  borderRadius: '8px',
  marginTop: '40px',
  '&:hover': {
    backgroundColor: '#FFCC00',
    color: '#000000',
    cursor: 'pointer',
  },
})
const NewStyledModalContainer = styled(Box)({
  '@media (max-width: 1370px)': {
    width: "170px",

  },
})

const NoTemplatesTypography = styled(Typography)({
  color: '#000000',
  fontWeight: 500,
  fontSize: '16px',
  padding: "10px"
})
const NamesTypography = styled(Typography)({
  color: '#000000',
  fontWeight: 400,
  fontSize: '14px'
})

const NewStyledSpan = styled('span')({
  fontSize: "16px",
  fontWeight: 500,
  color: "black"
})
const StyledSpan = styled('span')({
  color: 'red'
})
const StyledExpandMoreIcon = styled(ExpandMoreIcon)({
  position: 'absolute',
  right: '4px',
  top: '15px',
  cursor: 'pointer',
  color: 'black'
})
const StyledBox = styled(Box)({
  background: '#F3F4F5'
})
const TableBox = styled(Box)({
  marginRight: '25px'
})
// Customizable Area End