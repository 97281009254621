import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { setStorageData, getStorageData } from "../../../framework/src/Utilities";
import ToastNotification from "../../../components/src/ToastNotification.web";

export interface ValidResponseType {
  data: object;
  errors: Array<ErrorPayloadType>;
}
export interface ErrorPayloadType {
  key: string;
  message: string;
}
export interface ErrorResponseType {
  errors: string;
}
export interface InvalidResponseType {
  errors: Array<string>;
}

export interface KpiGamecardGetData {
  id: string,
  type: string,
  attributes: {
    id: number,
    name: string,
    title: string,
    description: string,
    status: string,
    department_id: number,
    kpis: KpisCard
  }
}

export interface KpiGamecard {
  data: KpiGamecardGetData
}

export interface KpisCard {
  Core: Array<KpisData>,
  Productivity: Array<KpisData>,
  Checklist: Array<KpisData>,
  Attendance: Array<KpisData>,
  Timing: Array<KpisData>,
  Occurrence: Array<KpisData>  
  Report: Array<KpisData>
}

export interface KpisData {
  kpi_id: number,
  kpi_desc: string,
  KPI: string,
  kpi_type: string,
  weightage: number,
  goal_value: number,
  goal_type: string,
  DKR_Title: Array<string>,
  DKR_Title_value: Array<string>,
  options: Array<object>,
  DKR_description: null,
  DKR_response_type: Array<string>,
  calculation_type: string,
  minimum_qualify_value: string,
  applicable_occurrences: Array<string>,
  app_occu_count: number
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  selectedTab:string;
  employeeName:any;
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  open:boolean;
  data: any[];
  statusName:string;
  loading: boolean;
  kpisData: Array<KpisData>;
  hasError: string;
  flag:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class GamificationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getKpiGamecardDataApiCallId: string | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      // Customizable Area End
    ];
    this.state = {
      selectedTab:"kpiAdmin",
      employeeName:"",
      flag:true,
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      open: false,
      data: [],
      statusName:'',
      loading: false,
      kpisData: [],
      hasError: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (this.isValidResponseController(responseJson)) {
        this.apiSuccessCallBacksController(apiRequestCallId, responseJson);
      }
      if (this.isInValidResponseController(responseJson)) {
        this.apiFailureCallBacksController(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };
  handleTabClick = (tabName:any) => {
    this.setState({ selectedTab: tabName });
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };
  // Customizable Area Start
  async componentDidMount() {
   const roleKpi= await getStorageData("newRole")
   if(roleKpi==="kpi_admin"){
    this.setState({flag:true})
   }
   else{this.setState({flag:false})}
    this.handleKpiGamecardData()
  }

  isValidResponseController = (responseJson: ValidResponseType) => {
    return responseJson && !responseJson.errors;
  };

  isInValidResponseController = (responseJson: InvalidResponseType) => {
    return responseJson && responseJson.errors;
  };

  apiSuccessCallBacksController = (apiRequestCallId: string, responseJson: KpiGamecard) => {
    if (apiRequestCallId === this.getKpiGamecardDataApiCallId) {
      this.getKpiGamecardDataSuccessCallBack(responseJson)
    }
  };

  apiFailureCallBacksController = (apiRequestCallId: string, responseJson: InvalidResponseType) => {
    if (apiRequestCallId === this.getKpiGamecardDataApiCallId) {
      this.getKpiGamecardDataFailurCallBack(responseJson)
    }
  };

  apiCall = async (valueData: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    apiType?: string;
    type?: string;
  }) => {
    let { contentType, method, endPoint, body } = valueData;
    const token = (await getStorageData("authToken")) || "";
    let header = {
      "Content-Type": contentType,
      token,
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  handleOpen = (newStatusName:string) => {
    this.setState({ statusName :newStatusName, open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  
  Goback=(path: any)=>{
    const toNavigate: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), path);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  }
  

subDashboard = (pathChange:any, label: string)=>{
  const toNavigate: Message = new Message(
    getName(MessageEnum.NavigationMessage)
  );
  toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), pathChange);
  toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), {
    ...this.props,
  });
  setStorageData('selectedLabel', label);
  this.send(toNavigate);
}
getEndpoint = async () => {
  const reporteeId = (await getStorageData("reporteeId"))
  return reporteeId ? `?direct_reportee_id=${reporteeId}` : '';
}
handleKpiGamecardData = async () => {
  try {
    this.setState({loading: true})
    this.getKpiGamecardDataApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.getKpiGamecardApiEndPoint}${await this.getEndpoint()}`
    });
  } catch (error) {
    console.error("Error in handleKpiGamecardData:", error);
  }
};


  getKpiGamecardDataSuccessCallBack =async (responseJson: KpiGamecard) => {
    const employeeName  = await getStorageData('newEmployeeName')
    this.setState({employeeName:employeeName})
    if(responseJson){
      const kpis = responseJson.data.attributes.kpis
      const allKpis = Object.values(kpis).flat();
      if(allKpis){
        this.setState({ kpisData: allKpis , loading: false})
      }
    }
  }

  getKpiGamecardDataFailurCallBack = (responseJson: InvalidResponseType) => {
    this.setState({ loading: false})
    ToastNotification.showError(responseJson.errors[0]);
  }
  // Customizable Area End
}
