import React from "react";
// Customizable Area Start
import {
  Button,
  FormControl,
  FormLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Divider,
  Box,
  styled,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  InputAdornment,
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
// Customizable Area End
import AddDkrController, { Props } from "./AddDkrController";
import PopupMessageWeb from "../../../components/src/PopupMessage.web";
export const theme = createTheme({
  palette: {
    primary: {
      contrastText: "#fff",
      main: "#fff",
    }
  },
  typography: {
    subtitle1: {
      margin: "20px 0px"
    },
    h6: {
      fontWeight: 500
    },
  }
});
export default class TimingDkr extends AddDkrController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const {KPIData, selectedCalType,occrTitle, checklistDescriptions, maxDescriptionLength, isOpenDialog, dialogMessage} = this.state
    const KpiDetail = KPIData.attributes
    const applicableData = KpiDetail.goal_type === 'number'
      ? this.state.rowDrkprorata
      : this.state.rowDrkprodata;

      const occName:  { [key: string]: string } = {
        timing: 'Late Occurrence',
        attendance: 'Absence Occurrence',
        report: 'Missed submission'
      }
      const getDisplayDKRType = (dkrType: string) => {
        switch (dkrType) {
          case 'captured_dkr':
            return 'Captured DKR';
          case 'gamified_dkr':
            return 'Gamified DKR';
          case 'captured_dkr_numerator':
            return 'Captured DKR (Numerator)';
          case 'captured_dkr_denominator':
            return 'Captured DKR (Denominator)';
          default:
            return dkrType;
        }
      };
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Box style={webStyle.Timemaincontainer}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid className="first" style={webStyle.TimeleftSide} item>
              <Grid container spacing={2} alignItems="center">
                <Grid item style={{...webStyle.TimerowarrowDisplay,cursor:'pointer'}}>
                  <ArrowBackIosIcon style={{ fontSize: "24px", color: "black", cursor:'pointer' }} data-test-id="nextButton1" onClick={this.navigateToEditTemplate2} />
                  <span
                    style={{ ...webStyle.TimeHeadStyleTop1, fontWeight: 400 }}>
                    Edit Template
                  </span>
                </Grid>
                <Grid 
                item style={webStyle.TimerowarrowDisplay}>
                  <ArrowBackIosIcon style={{ ...webStyle.TimeHeadStyleTop1, color: "#B2B2B2", fontSize: "16px" }} />
                  <span style={{ ...webStyle.TimelabelStyleoptionhead as React.CSSProperties, fontWeight: 700, fontSize: "18px" }}>
                    Add DKR Details
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <Grid 
            className="second" style={webStyle.TimenullContainer} item />
          </Grid>
          <Grid
            container
            alignItems="center"
            style={webStyle.TimeinformContainer as React.CSSProperties}>
            <Grid
              className="first"
              item
              style={webStyle.TimeinformSecondContainerHead}
              xs={12}>
              <div>
                <FormControl component="fieldset">
                  <FormLabel component="legend" style={webStyle.TimeheaderMain10}>
                    <span style={webStyle.TimeHeadtopStyle22 as React.CSSProperties}>KPI Details</span>
                  </FormLabel>

                </FormControl>
              </div>
              <div className="form-field text-center" id="signupBlock4">
                <div className="control" id="signupfieldcontrol2">
                  <Button style={webStyle.Editbutton as React.CSSProperties} data-test-id="EditButton" onClick={this.navigateToEditKpi}>
                    Edit KPI Details
                  </Button>
                </div>
              </div>
            </Grid>
            <Grid
              className="first"
              item
              style={webStyle.TimeinformationSubject}
              xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend" style={webStyle.TimeheaderMain10}>
                  <span style={{ ...webStyle.TimelabelStyleoptionhead as React.CSSProperties, fontSize: "22px", fontWeight: 500 }}>Type</span>
                </FormLabel>
                <span style={{ ...webStyle.TimeblurvalueStyle as React.CSSProperties, fontWeight: 400, textTransform: 'capitalize' }}>{KpiDetail.kpi_type}</span>
              </FormControl>
            </Grid>
            <Grid
              className="second"
              item
              style={webStyle.TimeinformationSubject}
              xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend" style={{ ...webStyle.TimeheaderMain10, marginTop: "30px" }}>
                  <span style={{ ...webStyle.TimelabelStyleoptionhead as React.CSSProperties, fontWeight: 500, fontSize: "22px" }}>Basic Details</span>
                </FormLabel>
                <Grid container style={{ display: "flex" }} spacing={6} xs={12}>
                  <Grid item xs={3}>
                    <div className="form-block" id="signupBlock3">
                      <form>
                        <div
                          className="form-field"
                          id="signupform"
                          style={webStyle.TimetopdescThirty}>
                          <span style={webStyle.TimeTopstyleHeader as React.CSSProperties}>Title</span>
                          <div
                            className="control"
                            id="signupfieldcontrol"
                            style={webStyle.TimemarginLeft0}>
                            <span style={webStyle.TimeblurvalueStyle as React.CSSProperties}>{KpiDetail.name}</span>
                          </div>
                        </div>
                      </form>
                    </div>
                  </Grid>
                  <>
                    <Grid item xs={6} spacing={6}>
                      <div className="form-block" id="signupBlock3">
                        <form>
                          <div
                            className="form-field"
                            id="signupform"
                            style={webStyle.TimetopdescThirty}
                          >
                            <span style={webStyle.TimeTopstyleHeader as React.CSSProperties}>Description</span>
                            <div
                              className="control"
                              id="signupfieldcontrol"
                              style={webStyle.TimemarginLeft0}
                            >
                              <div style={{ ...webStyle.TimeblurvalueStyle as React.CSSProperties, fontWeight: 400, height:'150px', width:'250px', overflow:'auto' }}>{KpiDetail.description}</div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <div className="form-block" id="signupBlock3">
                        <form>
                          <div
                            className="form-field"
                            id="signupform"
                            style={{ ...webStyle.TimetopdescThirty, width: "max-content" }}
                          >
                            <span style={webStyle.TimeTopstyleHeader as React.CSSProperties}>Points (Weightage)</span>
                            <div
                              className="control"
                              id="signupfieldcontrol"
                              style={webStyle.TimemarginLeft0}
                            >
                              <span style={{ ...webStyle.TimeblurvalueStyle as React.CSSProperties, fontWeight: 400 }}>{KpiDetail.weightage} Points</span>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Grid>
                  </>
                </Grid>
                {['productivity', 'core'].includes(KpiDetail.kpi_type) && KpiDetail.automatic_calculation &&
                <Box>
                <FormLabel component="legend" style={{ ...webStyle.TimeheaderMain10, marginTop: "30px" }}>
                  <span style={{ ...webStyle.TimelabelStyleoptionhead as React.CSSProperties, fontWeight: 500, fontSize: "22px" }}>Calculation Type</span>
                </FormLabel>
                <Grid container style={{ display: "flex" }} spacing={6} xs={12}>
                  <Grid item xs={3}>
                    <div className="form-block" id="signupBlock3">
                        <div
                          className="form-field"
                          id="signupform"
                          style={webStyle.TimetopdescThirty}>
                          <span style={webStyle.TimeTopstyleHeader as React.CSSProperties}>Automatic</span>
                          <div
                            className="control"
                            id="signupfieldcontrol"
                            style={webStyle.TimemarginLeft0}>
                            <span style={webStyle.TimeblurvalueStyle as React.CSSProperties}>{KpiDetail.automatic_calculation?'Yes':''}</span>
                          </div>
                        </div>
                    </div>
                  </Grid>
                  </Grid>
                  <FormLabel component="legend" style={{ ...webStyle.TimeheaderMain10, marginTop: "30px" }}>
                  <span style={{ ...webStyle.TimelabelStyleoptionhead as React.CSSProperties, fontWeight: 500, fontSize: "22px" }}>Monthly Goal Details</span>
                </FormLabel>
                <Grid container style={{ display: "flex" }} spacing={6} xs={12}>
                  {KpiDetail.kpi_type === 'core' && 
                  <Grid item xs={3}>
                    <div className="form-block" id="signupBlock3">
                        <div
                          className="form-field"
                          id="signupform"
                          style={webStyle.TimetopdescThirty}>
                          <span style={webStyle.TimeTopstyleHeader as React.CSSProperties}>Goal Type</span>
                          <div
                            className="control"
                            id="signupfieldcontrol"
                            style={{...webStyle.TimemarginLeft0, width:'250px'}}>
                            <span style={webStyle.TimeblurvalueStyle as React.CSSProperties}>{KpiDetail.goal_type}</span>
                          </div>
                        </div>
                    </div>
                  </Grid>}
                  {KpiDetail.goal_type === 'number' &&
                  <Grid item xs={4}>
                    <div className="form-block" id="signupBlock3">
                        <div
                          className="form-field"
                          id="signupform"
                          style={{...webStyle.TimetopdescThirty, width:'250px'}}>
                          <span style={webStyle.TimeTopstyleHeader as React.CSSProperties}>DKR Calculation Formula</span>
                          <div
                            className="control"
                            id="signupfieldcontrol"
                            style={webStyle.TimemarginLeft0}>
                            <span style={webStyle.TimeblurvalueStyle as React.CSSProperties}>{KpiDetail.calculation_method ==='sum_calculation'? "Sum": "Average"}</span>
                          </div>
                        </div>
                    </div>
                  </Grid>}
                  <Grid item xs={4}>
                    <div className="form-block" id="signupBlock3">
                        <div
                          className="form-field"
                          id="signupform"
                          style={{...webStyle.TimetopdescThirty, width:'250px'}}>
                          <span style={webStyle.TimeTopstyleHeader as React.CSSProperties}>{(KpiDetail.goal_type === 'percentage' && KpiDetail.kpi_type === 'core') ? 'Goal Value (In Percentge)' : 'Goal Value (In Numbers)'}</span>
                          <div
                            className="control"
                            id="signupfieldcontrol"
                            style={webStyle.TimemarginLeft0}>
                            <span style={webStyle.TimeblurvalueStyle as React.CSSProperties}>{(KpiDetail.goal_type === 'percentage' && KpiDetail.kpi_type === 'core') ? `${KpiDetail.goal_value}%` : KpiDetail.goal_value}</span>
                          </div>
                        </div>
                    </div>
                  </Grid>
                  </Grid>
                  <FormLabel component="legend" style={{ ...webStyle.TimeheaderMain10, marginTop: "30px" }}>
                  <span style={{ ...webStyle.TimelabelStyleoptionhead as React.CSSProperties, fontWeight: 500, fontSize: "22px" }}>Analytics & Run rate</span>
                </FormLabel>
                <Grid container style={{ display: "flex" }} spacing={6} xs={12}>
                  <Grid item xs={3}>
                    <div className="form-block" id="signupBlock3">
                        <div
                          className="form-field"
                          id="signupform"
                          style={webStyle.TimetopdescThirty}>
                          <span style={webStyle.TimeTopstyleHeader as React.CSSProperties}>Run Rate</span>
                          <div
                            className="control"
                            id="signupfieldcontrol"
                            style={{...webStyle.TimemarginLeft0, width:'250px'}}>
                            <span style={webStyle.TimeblurvalueStyle as React.CSSProperties}>{KpiDetail.run_rate?'Yes':'No'}</span>
                          </div>
                        </div>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="form-block" id="signupBlock3">
                        <div
                          className="form-field"
                          id="signupform"
                          style={webStyle.TimetopdescThirty}>
                          <span style={webStyle.TimeTopstyleHeader as React.CSSProperties}>Part of Analytics</span>
                          <div
                            className="control"
                            id="signupfieldcontrol"
                            style={{...webStyle.TimemarginLeft0, width:'250px'}}>
                            <span style={webStyle.TimeblurvalueStyle as React.CSSProperties}>{KpiDetail.part_of_analytics?'Yes':'No'}</span>
                          </div>
                        </div>
                    </div>
                  </Grid>
                  {KpiDetail.part_of_analytics && 
                  <Grid item xs={4}>
                    <div className="form-block" id="signupBlock3">
                        <div
                          className="form-field"
                          id="signupform"
                          style={webStyle.TimetopdescThirty}>
                          <span style={webStyle.TimeTopstyleHeader as React.CSSProperties}>Analytics Parameter</span>
                          <div
                            className="control"
                            id="signupfieldcontrol"
                            style={{...webStyle.TimemarginLeft0, width:'350px'}}>
                            <span style={webStyle.TimeblurvalueStyle as React.CSSProperties}>{this.getNameById(KpiDetail.analytics_parameter_id)}</span>
                          </div>
                        </div>
                    </div>
                  </Grid>}
                  </Grid>
                </Box>}
              </FormControl>
            </Grid >



          </Grid >
          <StyledDivider light  />
          <Grid
            container
            alignItems="center"
            style={webStyle.TimeinformContainer as React.CSSProperties}
          >
            <Grid
              className="first"
              item
              style={webStyle.TimeinformSecondContainerHead}
              xs={12}
            >
              <div>
                <FormControl component="fieldset">
                  <FormLabel component="legend" style={webStyle.TimeheaderMain10}>
                    <span style={webStyle.TimeHeadtopStyle22 as React.CSSProperties}>Daily KPI Report</span>
                  </FormLabel>

                </FormControl>
              </div>
            </Grid>
            {KpiDetail.kpi_type === 'core' && !KpiDetail.automatic_calculation &&
            <Grid className="first"
              item
              style={webStyle.TimeinformationSubContainerTable}
              xs={12}  >
              <>
              <FormControl component="fieldset">
                <FormLabel component="legend" style={webStyle.Timeheadermandatory}><span style={{ ...webStyle.labelStyle10 as React.CSSProperties, fontWeight: 500 }}>Applicable DKRs</span>
                </FormLabel>
              </FormControl>
              <TableContainer style={webStyle.TimetablemainContainer}>
                  <Table aria-label="customized table">
                    <TableHead>
                       <TableRow>
                       <TableCell style={{ fontWeight: 'bold' }}>Sr. No.</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>DKR Brief Title</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>DKR Response</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>DKR Response</TableCell>
                        <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                        <Button
                            onClick={this.handleAddRow}
                            style={{ fontFamily: 'Open Sans', 
                              backgroundColor: "FDD100", 
                              color: "black", 
                              fontSize: "0.875rem", fontWeight: 600, 
                              textTransform: "none", paddingTop: "6px", padding:'7px 25px', borderRadius: "10px" }}
                            >Add DKR</Button>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.rows.map((row: any, rowIndex:number) => (
                        <React.Fragment key={row.id}>
                        <TableRow>
                          <TableCell>{rowIndex + 1}</TableCell>
                            <TableCell>
                              <TextField
                                  fullWidth
                                  onChange={(e) => this.handleTitleChange(rowIndex, e.target.value)}
                                  value={row.title}
                                  style={webStyle.titleField}
                                  InputProps={{
                                    disableUnderline: true,
                                    style: {
                                      textAlign: "center",
                                      padding: "5px",
                                    }
                                  }}/>
                            </TableCell>
                            <TableCell>
                              <Box style={{border: "0.25px solid #979797",borderRadius: "12px", paddingRight:'12px'}}>
                              <select
                                  value={row.responseType}
                                  style={webStyle.dkrDropdown}
                                  onChange={(e) => this.handleResponseTypeChange(rowIndex, e.target.value)}
                                >
                                  <option value="manual">Manual</option>
                                  <option value="dropdown">Dropdown</option>
                                </select>
                                </Box>
                              </TableCell>
                              <TableCell>
                                {row.responseType === 'dropdown' && (
                                  <Button
                                    onClick={() => this.handleAddOptions(rowIndex)}
                                    style={{ color: 'blue', textTransform: 'none' }}
                                  >
                                    Add Option
                                  </Button>
                                )}</TableCell>
                              <TableCell>
                                <Button
                                  onClick={() => this.handleRemoveRow(rowIndex)}
                                  style={{ display: rowIndex === 0? 'none':'block', color: 'red', textTransform: 'none', fontSize:'16px', textDecoration: 'underline',textUnderlineOffset:'3px', marginLeft:'65px' }}
                                >
                                  Remove DKR
                                </Button>
                              </TableCell>
                            </TableRow>
                            {row.responseType === 'dropdown' &&
                              row.options.map((option: any, optionIndex: number) => (
                                <TableRow key={`${rowIndex}-${optionIndex}`} style={{backgroundColor:'#F5F5F5'}}>
                                  <TableCell>{`Option ${optionIndex + 1}`}</TableCell>
                                  <TableCell>
                                    <TextField
                                      value={option}
                                      onChange={(e) => this.handleOptionChange(rowIndex, optionIndex, e.target.value)}
                                      fullWidth
                                      style={webStyle.titleField}
                                      InputProps={{
                                        disableUnderline: true,
                                        style: {
                                          textAlign: "center",
                                          padding: "5px",
                                        }
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <Button
                                      onClick={() => this.handleRemoveOptions(rowIndex, optionIndex)}
                                      style={{...webStyle.optionRemoveBtn,display: optionIndex === 0? 'none':'block'}}
                                    >
                                      Remove
                                    </Button>
                                  </TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                </TableRow>
                              ))}
                          </React.Fragment>
                        ))}
                       
                      </TableBody>
                    </Table>
                </TableContainer>

              </>
            </Grid>}
            {['timing', 'attendance', 'report'].includes(KpiDetail.kpi_type) && 
            <Grid
              className="first"
              item
              style={webStyle.TimeinformationSubContainerTable}
              xs={12}  >
              <>
                <FormControl component="fieldset">
                  <FormLabel component="legend" style={webStyle.Timeheadermandatory}>
                    <span style={{ ...webStyle.labelStyle10 as React.CSSProperties, fontWeight: 500 }}>Applicable Occurrence</span>
                  </FormLabel>
                </FormControl>
                <TableContainer style={webStyle.TimetablemainContainer}>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow 
                      style={webStyle.TimemaintableHead}
                      >
                        <TableCell 
                        style={{ fontFamily: "Open Sans", fontSize: "14px", color: "black", fontWeight: 600, paddingLeft: "70px", width: "33%" }}
                        >Occurrence Title</TableCell>
                        <TableCell style={{ 
                          fontFamily: "Open Sans", 
                          fontSize: "14px", 
                          color: "black", 
                          fontWeight: 600, 
                          width: "33%" 
                          }}>
                          Deductible Value
                        </TableCell>
                        <TableCell style={{ 
                          width: "18%" 
                          }}>
                          <Button 
                          onClick={this.handleAddProdRow} 
                          style={{ fontFamily: 'Open Sans', 
                          backgroundColor: "FDD100", 
                          color: "black", 
                          marginRight: "20px", 
                          width: "220px", 
                          fontSize: "16px", fontWeight: 600, textTransform: "none", paddingTop: "6px", paddingBottom: "6px", borderRadius: "10px" }}>
                            Add Occurrence
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.rowProductivity.map((row: any, index: any) => (
                        <TableRow key={row.id}>
                          <TableCell component="th" scope="row" 
                          style={{ fontFamily: "Open Sans", fontSize: "14px", color: "black", paddingLeft: "65px" }}>{index + 1}. {occName[KpiDetail.kpi_type]}</TableCell>
                          <TableCell>
                            <Box className="form-block" id="signupBlock3">
                              <form>
                                <Box
                                  id="signupform"
                                  className="form-field"
                                >
                                  <Box
                                    className="control"
                                    style={webStyle.TimemarginLeft0}
                                    id="signupfieldcontrol"
                                  >
                                    <StyledTextField
                                      data-test-id="txtInputEmail"
                                      style={webStyle.qualifiedResult}
                                      fullWidth
                                      InputProps={{
                                        disableUnderline: true,
                                        style: {
                                          textAlign: "center",
                                          padding: "5px",
                                        }
                                      }}
                                      onChange={(e) => this.handleProdChange(index, 'value', e.target.value)}
                                      name="email"
                                    />
                                    {!row.value && (
                                      <div style={{ color: "red" }}>
                                        {this.state.timingError}
                                      </div>
                                    )}
                                      </Box>
                                </Box>
                              </form>
                            </Box>
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            <a 
                            style={{ fontSize:'16px', color: "#ff5c5c", textDecoration: "underline" }}
                            data-test-id="clickforRemoveTime"
                            onClick={() => this.handleRemoveProRow(index)} 
                            >Remove Occurrence</a>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

              </>
            </Grid>}
            {KpiDetail.kpi_type === 'core' && KpiDetail.automatic_calculation &&
              <Grid
               className="first"
               item
               style={webStyle.TimeinformationSubContainerTable}
               xs={12}  >
              <>
                <FormControl component="fieldset">
                  <FormLabel component="legend" style={webStyle.Timeheadermandatory}>
                    <span style={{ ...webStyle.labelStyle10 as React.CSSProperties, fontWeight: 500 }}>Calculation Type</span>
                  </FormLabel>
                    <RadioGroup
                      data-testid="singleuserCheck"
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      defaultValue="slab"
                      onChange={this.handleRadioChange}
                      value={selectedCalType}
                      style={{marginTop: '10px'}}>
                      <FormControlLabel
                        control={<Radio style={webStyle.Radio1} />}
                        value="slab"
                        style={{ fontFamily: 'Open Sans', fontSize: '14px', color: '#777', }}
                        label="Slab"/>
                      <FormControlLabel
                        style={{ fontSize: '14px', color: '#777', fontFamily: 'Open Sans', }}
                        control={<Radio style={webStyle.Radio1} />}
                        value="pro_rata"
                        label="Pro rata" /> </RadioGroup>
                     </FormControl><FormLabel component="legend" style={webStyle.Timeheadermandatory}>
                      <span style={{ ...webStyle.labelStyle10 as React.CSSProperties, fontWeight: 500 }}>Define Calculation Type</span></FormLabel>
                   
                   {selectedCalType === 'pro_rata' &&  <Box style={{marginTop: "10px", display: 'inline-grid'}}>
                     <Typography style={{ fontSize: "18px", fontWeight: 700, color: 'black' }}>Minimum Qualified Result (in %)</Typography>
                     <StyledTextField value={this.state.qualifiedPercent}
                      onBlur={this.handleQualifiedBlur}
                      onChange={this.handleQualifiedChange}
                      style={webStyle.qualifiedResult}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                        disableUnderline: true,
                        style: {
                          textAlign: "center", padding: "5px",
                        }
                      }} />
                      {this.state.QualiError && (
                        <div style={{ color: "red" }}> {this.state.QualiError} </div>
                      )} </Box>}
                 
                  {selectedCalType === 'slab' &&  <Box style={webStyle.containerslab}>
                    <Table style={{marginLeft:'-16px'}}><TableHead >
                      <TableRow ><TableCell style={{ fontSize: '16px', fontWeight: 700}}>No. of Slabs</TableCell>
                        <TableCell style={{ fontSize: '16px', fontWeight: 700}}>Min.</TableCell><TableCell style={{ fontSize: '16px', fontWeight: 700}}>Max.</TableCell>
                        <TableCell style={{ fontSize: '16px', fontWeight: 700}}>Weightage</TableCell><TableCell></TableCell>
                        <TableCell></TableCell>
                       </TableRow>
                    </TableHead><TableBody>
                  {this.state.rowSlab.map((row, index) => (<TableRow key={row.id}>
                        <TableCell style={{ width: "12%" }}>
                        <StyledTextField
                                  disabled={true}
                                  style={{...webStyle.qualifiedResult, backgroundColor:'#f3f4f5'}}
                                  value={`${row.id}.`}
                                  InputProps={{
                                    disableUnderline: true,
                                    style:{padding:'5px'}
                                  }}
                                  fullWidth />
                              </TableCell>
                            <TableCell  style={{ width: "10%" }}>
                              <StyledTextField style={webStyle.qualifiedResult}
                                  value={row.min}
                                  InputProps={{
                                    disableUnderline: true,
                                    endAdornment: (
                                      <InputAdornment position="end">%</InputAdornment>
                                    ),
                                    style: {
                                      padding: "5px",
                                      appearance: "none",
                                      MozAppearance: "textfield",
                                      textAlign: "center",
                                      WebkitAppearance: "none",
                                    },
                                  }}
                                  fullWidth
                                  onChange={(e) =>this.handleRowSlabChange(row.id,'min',e.target.value)} />
                            </TableCell><TableCell style={{ width: "10%" }}>
                            <StyledTextField style={webStyle.qualifiedResult}
                            value={row.max}
                            InputProps={{
                            disableUnderline: true,
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            ),
                             style: {
                               textAlign: "center",padding: "5px",
                               }
                             }}
                           onChange={(e) =>this.handleRowSlabChange(row.id,'max',e.target.value)}
                            fullWidth />
                           </TableCell><TableCell style={{ width: "10%" }}>
                          <StyledTextField value={row.weightage}
                          style={webStyle.qualifiedResult}
                          InputProps={{
                          disableUnderline: true,
                           style: {textAlign: "center", padding: "5px"}}}
                          onChange={(e) =>this.handleRowSlabChange(row.id,'weightage',e.target.value)}
                           fullWidth /></TableCell>
                            <TableCell>{index !== 0 && (<Button
                           variant="outlined"
                            style={{...webStyle.removeBtnslab, textTransform: 'capitalize'}}
                           onClick={() => this.handleRemoveCalRow(row.id)}>Remove</Button>)}
                          {index === this.state.rowSlab.length - 1 && (
                          <Button
                           variant="outlined"
                          onClick={this.handleAddCalRow}
                         style={{...webStyle.addBtnslab,textTransform: 'capitalize'}}>Add</Button>)}
                      </TableCell>
                        </TableRow> ))}
                      </TableBody>
                    </Table>
                      {this.state.slabError && ( <div style={{ color: "red" }}>
                          {this.state.slabError} </div>
                      )}
               </Box>}
                <FormLabel component="legend" style={webStyle.Timeheadermandatory}>
                <span style={{ ...webStyle.labelStyle10 as React.CSSProperties, fontWeight: 500, marginTop: '15px' }}>Applicable DKRs</span>
                  </FormLabel>
              <TableContainer style={webStyle.TimetablemainContainer}>
              <Table aria-label="customized table" style={{width: '70%'}}>
                  <TableHead>
                     <TableRow>
                      <TableCell style={{ fontWeight: 'bold' }}>Sr. No.</TableCell>
                         <TableCell style={{ fontWeight: 'bold' }}>DKR Type</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>DKR Brief Title</TableCell>
                      </TableRow>
                  </TableHead>
                  <TableBody>
                    {applicableData.map((row: any, rowIndex:number) => (
                      <React.Fragment key={row.id}>
                         <TableRow>
                          <TableCell>{rowIndex + 1}</TableCell>
                             <TableCell><Typography>{getDisplayDKRType(row.DKRtype)}</Typography>
                            </TableCell>
                            <TableCell>
                            <TextField
                              value={row.DkrTitle}
                               onChange={(e) => this.handleProChange(rowIndex, e.target.value)}
                                 fullWidth
                                 style={webStyle.titleField}
                                 InputProps={{
                                disableUnderline: true,
                                style: {
                                      textAlign: "center",
                                      padding: "5px",
                                    }
                                  }} />
                            {!row.DkrTitle && (
                              <div style={{ color: "red" }}>
                                {this.state.titleError}
                              </div>
                            )}
                             </TableCell>
                             </TableRow>
                        </React.Fragment>
                        ))}
                       
                   </TableBody>
                    </Table>
                </TableContainer>
              </>
            </Grid>}
            {KpiDetail.kpi_type === 'productivity' &&
            <Grid
              className="first"
              style={webStyle.TimeinformationSubContainerTable}
              item
              xs={12} >
              <>
                <FormControl component="fieldset">
                    <FormLabel component="legend" style={webStyle.Timeheadermandatory}>
                     <span style={{ ...webStyle.labelStyle10 as React.CSSProperties, fontWeight: 500 }}>Calculation Type</span>
                   </FormLabel>
                     <RadioGroup
                        data-testid="singleuserCheck"
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        row
                        defaultValue="slab"
                        name="row-radio-buttons-group"
                        onChange={this.handleRadioChange}
                        style={{marginTop: '10px'}}
                      >
                        <FormControlLabel
                          value="slab"
                          control={<Radio style={webStyle.Radio1} />}
                         style={{ fontFamily: 'Open Sans', fontSize: '14px', color: '#777', }}
                         label="Slab"
                      />
                        <FormControlLabel
                          value="pro_rata"
                          control={<Radio style={webStyle.Radio1} />}
                          style={{ fontSize: '14px', color: '#777', fontFamily: 'Open Sans', }}
                          label="Pro rata"
                        />
                    </RadioGroup>
                    </FormControl>
                    <FormLabel component="legend" style={webStyle.Timeheadermandatory}>
                        <span style={{ ...webStyle.labelStyle10 as React.CSSProperties, fontWeight: 500 }}>Define Calculation Type</span>
                      </FormLabel>
                      {selectedCalType === 'pro_rata' && 
                      <Box style={{marginTop: "10px", display: 'inline-grid'}}>
                      <Typography style={{ fontSize: "18px", fontWeight: 700, color: 'black' }}>Minimum Qualified Result (in %)</Typography>
                      <StyledTextField
                        value={this.state.qualifiedPercent}
                        onChange={this.handleQualifiedChange}
                        onBlur={this.handleQualifiedBlur}
                        style={webStyle.qualifiedResult}
                        fullWidth
                        InputProps={{
                          disableUnderline: true,
                          style: {
                            textAlign: "center",
                            padding: "5px",
                          },
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          )
                        }} />
                      {this.state.QualiError && (<div style={{ color: "red" }}>
                          {this.state.QualiError}
                        </div>
                      )}
                      </Box>}
                        {selectedCalType === 'slab' &&
                      <Box style={webStyle.containerslab}>
                          <Table><TableHead >
                              <TableRow><TableCell style={{ fontSize: '16px', fontWeight: 700}}>No. of Slabs</TableCell>
                                <TableCell style={{ fontSize: '16px', fontWeight: 700}}>Min.</TableCell>
                                <TableCell style={{ fontSize: '16px', fontWeight: 700}}>Max.</TableCell><TableCell style={{ fontSize: '16px', fontWeight: 700}}>Weightage</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell></TableRow></TableHead>
                            <TableBody>
                              {this.state.rowSlab.map((row, index) => (
                                <TableRow key={row.id}><TableCell style={{ width: "12%" }}>
                                  <StyledTextField
                                    style={{ ...webStyle.qualifiedResult, backgroundColor: '#f3f4f5' }}
                                    value={`${row.id}.`}
                                    disabled={true}
                                    InputProps={{
                                      disableUnderline: true,
                                      style: { padding: '5px' }
                                    }}
                                    fullWidth />
                              </TableCell>
                               <TableCell  style={{ width: "10%" }}>
                                    <StyledTextField
                                      style={webStyle.qualifiedResult}
                                      value={row.min}
                                      InputProps={{
                                        disableUnderline: true,
                                        endAdornment: (
                                          <InputAdornment position="end">%</InputAdornment>
                                        ),
                                        style: {
                                          textAlign: "center",padding: "5px",
                                          appearance: "none", MozAppearance: "textfield",
                                          WebkitAppearance: "none",
                                        },
                                      }}
                                      fullWidth
                                      onChange={(e) => this.handleRowSlabChange(row.id, 'min', e.target.value)} />
                            </TableCell><TableCell style={{ width: "10%" }}>
                                    <StyledTextField
                                      value={row.max}
                                      style={webStyle.qualifiedResult}
                                      InputProps={{
                                        disableUnderline: true,
                                        endAdornment: (
                                          <InputAdornment position="end">%</InputAdornment>
                                        ),
                                        style: {
                                          textAlign: "center", padding: "5px",
                                        }
                                      }}
                                      onChange={(e) => this.handleRowSlabChange(row.id, 'max', e.target.value)}
                                      fullWidth />
                            </TableCell>
                            <TableCell style={{ width: "10%" }}>
                            <StyledTextField value={row.weightage}
                          style={webStyle.qualifiedResult}
                          InputProps={{
                          disableUnderline: true,
                           style: {textAlign: "center", padding: "5px"}}}
                          onChange={(e) =>this.handleRowSlabChange(row.id,'weightage',e.target.value)}
                           fullWidth /></TableCell>
                            <TableCell>
                                  {index !== 0 && (<Button
                           variant="outlined"
                            style={{...webStyle.removeBtnslab, textTransform: 'capitalize'}}
                           onClick={() => this.handleRemoveCalRow(row.id)}>Remove</Button>)}
                                    {index === this.state.rowSlab.length - 1 && (
                                      <Button
                                      onClick={this.handleAddCalRow}
                                        variant="outlined"
                                        style={{...webStyle.addBtnslab, textTransform: 'capitalize'}} >
                                        Add </Button>
                                    )}
                                  </TableCell> </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                      {this.state.slabError && (
                        <div style={{ color: "red" }}>{this.state.slabError}</div>
                      )}
                      </Box>}
                      <FormLabel component="legend" style={webStyle.Timeheadermandatory}>
                        <span style={{ ...webStyle.labelStyle10 as React.CSSProperties, fontWeight: 500, marginTop: '15px' }}>Applicable DKRs</span>
                      </FormLabel>
                      <TableContainer style={webStyle.TimetablemainContainer}>
                        <Table aria-label="customized table">
                          <TableHead>
                            <TableRow>
                              <TableCell style={{ fontWeight: 'bold' }}>Sr. No.</TableCell>
                              <TableCell style={{ fontWeight: 'bold' }}>DKR Brief Title (Tasks)</TableCell>
                              <TableCell style={{ fontWeight: 'bold' }}>DKR SAM (in minutes)</TableCell>
                              <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                                <Button
                                  onClick={this.handleAddProdRow}
                                  style={{ fontFamily: 'Open Sans', 
                                    backgroundColor: "FDD100", 
                                    color: "black", 
                                    fontSize: "0.875rem", fontWeight: 600, 
                                    textTransform: "none", paddingTop: "6px", padding:'7px 25px', borderRadius: "10px" }}
                                >
                                  Add DKR
                                </Button>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.rowProductivity.map((row: any, rowIndex:number) => (
                              <React.Fragment key={row.id}>
                                <TableRow>
                                  <TableCell>{rowIndex + 1}</TableCell>
                                  <TableCell>
                                    <TextField
                                      value={row.title}
                                      onChange={(e) => this.handleProdChange(rowIndex, 'title', e.target.value)}
                                      fullWidth
                                      style={webStyle.titleField}
                                      InputProps={{
                                        disableUnderline: true,
                                        style: {
                                          textAlign: "center",
                                          padding: "5px",
                                        }
                                      }}
                                    />
                                    {!row.title && (
                                      <div style={{ color: "red" }}>
                                        {this.state.timingError}
                                      </div>
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    <TextField
                                      value={row.value}
                                      onChange={(e) => this.handleProdChange(rowIndex, 'value', e.target.value)}
                                      fullWidth
                                      style={{...webStyle.titleField, width:'25%'}}
                                      InputProps={{
                                        disableUnderline: true,
                                        style: {
                                          textAlign: "center",
                                          padding: "5px",
                                        }
                                      }}
                                    />
                                    {!row.value && (
                                      <div style={{ color: "red" }}>
                                        {this.state.timingError}
                                      </div>
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    <Button
                                      onClick={() => this.handleRemoveProRow(rowIndex)}
                                      style={{display: rowIndex === 0? 'none':'block', color: 'red', textTransform: 'none', fontSize:'16px', textDecoration: 'underline',textUnderlineOffset:'3px', marginLeft:'120px' }} >Remove DKR
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              </React.Fragment>
                            ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  </>
                </Grid>}
            {KpiDetail.kpi_type === 'checklist' &&
              <Grid
                className="first"
                item
                style={webStyle.TimeinformationSubContainerTable}
                xs={12}  >
                <>
                    <Box style={{marginTop: "10px", display: 'inline-grid'}}>
                      <Typography style={{ fontSize: "18px", fontWeight: 700, color: 'black' }}>Reminder Description</Typography>
                      <TextField
                        rows={6}
                      multiline
                        fullWidth
                        placeholder="Type here..."
                      inputProps={{
                          maxLength: 1000,
                        }}
                        InputProps={{
                          disableUnderline: true,
                        }}
                        style={webStyle.TextField2}
                      value={checklistDescriptions}
                        name="checklistDescriptions"
                       onChange={this.handleInputChange} />
                    <Typography variant="caption" style={webStyle.Typography2}>{checklistDescriptions.length}/{maxDescriptionLength}</Typography>
                    {!checklistDescriptions && (
                                      <div style={{ color: "red", marginTop:'-23px' }}>
                                        {this.state.checklistError}
                                      </div>
                                    )}
                  </Box>
                <FormLabel component="legend" style={webStyle.Timeheadermandatory}>
                  <span style={{ ...webStyle.labelStyle10 as React.CSSProperties, fontWeight: 500, marginTop: '15px' }}>Applicable DKRs</span>
              </FormLabel>
              <TableContainer style={webStyle.TimetablemainContainer}>
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontWeight: 'bold' }}>Sr. No.</TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>Checklist Options</TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>Checklist Options Description</TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>Value</TableCell>
                      <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                        <Button
                              onClick={this.handleAddProdRow}
                              style={{ fontFamily: 'Open Sans', 
                                backgroundColor: "FDD100", 
                                color: "black", 
                                fontSize: "0.875rem", fontWeight: 600, 
                                textTransform: "none", paddingTop: "6px", padding:'7px 40px', borderRadius: "10px" }}
                            >
                              Add Checklist Option</Button>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    {this.state.rowProductivity.map((row: any, rowIndex:number) => (
                        <React.Fragment key={row.id}>
                           <TableRow>
                           <TableCell>{rowIndex + 1}</TableCell>
                          <TableCell>Checklist Option {rowIndex + 1}</TableCell>
                          <TableCell>
                            <TextField
                                  value={row.title}
                                  onChange={(e) => this.handleProdChange(rowIndex, 'title', e.target.value)}
                                  fullWidth
                                  style={webStyle.titleField}
                                  InputProps={{
                                    disableUnderline: true,
                                    style: {
                                      textAlign: "center",
                                      padding: "5px",
                                    }
                                  }} />
                            </TableCell>
                            <TableCell style={{width:'15%'}}>
                            <TextField
                                  value={row.value}
                                  onChange={(e) => this.handleProdChange(rowIndex, 'value', e.target.value)}
                                  fullWidth
                                  style={webStyle.titleField}
                                  InputProps={{
                                    disableUnderline: true,
                                    style: {
                                      textAlign: "center",
                                      padding: "5px",
                                    }
                                  }}/>
                           </TableCell>
                          <TableCell style={{width:'25%'}}>
                                <Button
                                  onClick={() => this.handleRemoveProRow(rowIndex)}
                                  style={{ display: rowIndex === 0? 'none':'block', color: 'red', textTransform: 'none', fontSize:'16px', textDecoration: 'underline',textUnderlineOffset:'3px', marginLeft:'35px' }}
                                >Remove Checklist Option
                                </Button>
                              </TableCell>
                              </TableRow>
                            </React.Fragment>
                        ))}
                       
                      </TableBody>
                    </Table>
                </TableContainer>
              </>
            </Grid>}
            {KpiDetail.kpi_type === 'occurrence' &&
            <Grid
              className="first"
              item
              style={webStyle.TimeinformationSubContainerTable}
              xs={12}  >
              <>
                <FormControl component="fieldset">
                  <FormLabel component="legend" style={webStyle.Timeheadermandatory}>
                    <span style={{ ...webStyle.labelStyle10 as React.CSSProperties, fontWeight: 500 }}>Applicable DKRs</span>
                  </FormLabel>
                </FormControl>
                  <TableContainer style={webStyle.TimetablemainContainer}>
                    <Table aria-label="customized table">
                       <TableHead>
                          <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>Sr. No.</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>DKR Brief Title</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>DKR Response</TableCell>
                            <TableCell style={{ width:'40%' }}></TableCell>
                          </TableRow>
                       </TableHead>
                      <TableBody>
                        <React.Fragment>
                            <TableRow>
                              <TableCell>1</TableCell>
                              <TableCell>
                             <TextField
                                  onChange={this.handleOccTitle}
                                  fullWidth
                                  style={webStyle.titleField}
                                  InputProps={{
                                    disableUnderline: true,
                                    style: {
                                      textAlign: "center",
                                      padding: "5px",
                                    }
                                  }}
                            />
                            {!occrTitle && (
                                      <div style={{ color: "red" }}>
                                        {this.state.occError}
                                      </div>
                                    )}
                              </TableCell>
                              <TableCell>
                            <Box style={{ border: "0.25px solid #979797", borderRadius: "12px", paddingRight: '12px' }}>
                               <select
                                    defaultValue={'Dropdown'}
                                    style={webStyle.dkrDropdown}
                                    disabled={true}
                                  >
                                <option value="Dropdown">Dropdown</option>
                              </select>
                                </Box>
                                {!occrTitle && (
                                      <div style={{visibility:'hidden' }}>
                                        {this.state.occError}
                                      </div>
                                    )}
                              </TableCell>
                            </TableRow>
                            </React.Fragment>
                         </TableBody>
                      </Table>
                     </TableContainer>
                    <FormLabel component="legend" style={{...webStyle.Timeheadermandatory, marginTop: '24px'}}>
                      <span style={{ ...webStyle.labelStyle10 as React.CSSProperties, fontWeight: 500 }}>DKR Options</span>
                    </FormLabel>
                  <TableContainer style={webStyle.TimetablemainContainer}>
                    <Table aria-label="customized table">
                      <TableHead>
                          <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>Sr. No.</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Response Option</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Response Nature</TableCell>
                            <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                              <Button
                                onClick={this.handleAddRowOccDkr}
                                style={{
                                  fontFamily: 'Open Sans',
                                  backgroundColor: "FDD100",
                                  color: "black",
                                  fontSize: "0.875rem", fontWeight: 600,
                                  textTransform: "none", paddingTop: "6px", padding: '7px 25px', borderRadius: "10px"
                                }}
                              >
                                Add New DKR Response
                              </Button>
                          </TableCell>
                        </TableRow>
                         </TableHead>
                        <TableBody>
                           {this.state.rowOccDkr.map((row: any, rowIndex: number) => (
                          <React.Fragment key={row.id}>
                              <TableRow>
                              <TableCell>{rowIndex + 1}</TableCell>
                                  <TableCell>
                                <TextField
                                    onChange={(e) => this.handleOccChange(rowIndex, 'resOption', e.target.value)}
                                  fullWidth
                                    style={webStyle.titleField}
                                  InputProps={{
                                       disableUnderline: true,
                                      style: {
                                        textAlign: "center",
                                      padding: "5px",
                                      }
                                  }}
                                  />
                                  {row.resOption === '' && (
                                      <div style={{color: "red" }}>
                                        {this.state.occError}
                                      </div>
                                    )}
                              </TableCell>
                              <TableCell>
                                  <Box style={{ border: "0.25px solid #979797", borderRadius: "12px", paddingRight: '12px' }}>
                                  <select
                                    value={row.responseType}
                                    defaultValue='positive'
                                    onChange={(e) => this.handleOccChange(rowIndex, 'resNature', e.target.value)}
                                    style={webStyle.dkrDropdown}
                                  >
                                     <option value="positive">Positive</option>
                                     <option value="negative">Negative</option>
                                  </select>
                                </Box>
                                {row.resOption === '' && (
                                      <div style={{visibility:'hidden' }}>
                                        {this.state.occError}
                                      </div>
                                    )}
                                </TableCell>
                                <TableCell>
                                <Button
                                  onClick={() => this.handleRemoveRowOccDkr(rowIndex)}
                                  style={{ display: rowIndex === 0 ? 'none' : 'block', color: 'red', textTransform: 'none', fontSize: '16px', textDecoration: 'underline', textUnderlineOffset: '3px', marginLeft: '65px' }}
                                >Remove DKR</Button>
                                </TableCell>
                              </TableRow>
                            </React.Fragment>
                          ))}

                        </TableBody>
                    </Table>
                      </TableContainer>
                      <FormLabel component="legend" style={{...webStyle.Timeheadermandatory, marginTop: '24px'}}>
                        <span style={{ ...webStyle.labelStyle10 as React.CSSProperties, fontWeight: 500 }}>Applicable Occurrence Information</span>
                      </FormLabel>
                      <TableContainer style={webStyle.TimetablemainContainer}>
                      <Table aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>Sr. No.</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Occurrence Title</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Deductible Value</TableCell>
                            <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                              <Button
                                onClick={this.handleAddRowAppOcc}
                                style={{
                                  fontFamily: 'Open Sans',
                                  backgroundColor: "FDD100",
                                  color: "black",
                                  fontSize: "0.875rem", fontWeight: 600,
                                  textTransform: "none", paddingTop: "6px", padding: '7px 25px', borderRadius: "10px"
                                }}
                              >
                              Add Occurrence</Button>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                          {this.state.rowAppOcc.map((row: any, rowIndex: number) => (
                            <React.Fragment key={row.id}>
                              <TableRow>
                                <TableCell>{rowIndex + 1}</TableCell>
                                 <TableCell>
                                  {`${rowIndex + 1} Negative Response`}
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    value={row.deductVal}
                                    onKeyPress={this.handleNumberPress}
                                    onChange={(e) => this.handleDeductValChange(rowIndex, e.target.value)}
                                    fullWidth
                                    style={webStyle.titleField}
                                    InputProps={{
                                      disableUnderline: true,
                                      style: {
                                        textAlign: "center",
                                        padding: "5px",
                                      }
                                    }}
                                  />
                                  {row.deductVal === '' && (
                                      <div style={{color: "red" }}>
                                        {this.state.occError}
                                      </div>
                                    )}
                                </TableCell>
                                <TableCell>
                                  <Button
                                    onClick={() => this.handleRemoveRowAppOcc(rowIndex)}
                                    style={{ display: rowIndex === 0 ? 'none' : 'block', color: 'red', textTransform: 'none', fontSize: '16px', textDecoration: 'underline', textUnderlineOffset: '3px', marginLeft: '65px' }}
                                  >
                                    Remove DKR
                                  </Button>
                                </TableCell>
                              </TableRow>
                            </React.Fragment>
                          ))}

                      </TableBody>
                    </Table>
                  </TableContainer>
              </>
            </Grid>}
          </Grid >

            <Grid style={webStyle.TimebuttonmainContainer}>
              <Box style={{ marginTop: '50px', marginBottom: '40px' }}>
                <Button style={webStyle.TimeSubmitBtn as React.CSSProperties} onClick={this.handleSubmitDkr}>
                  Submit
                </Button>
                <Button variant="outlined" style={webStyle.TimeCancelBtn as React.CSSProperties} onClick={this.navigateToEditTemplate2} >
                  Cancel
                </Button>
              </Box>
          </Grid>
          <PopupMessageWeb
            open={isOpenDialog}
            onClose={this.handlecloseDialog}
            title="Error"
            description={dialogMessage} />
        </Box >
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const styles = {
  container: {
    padding: "20px",
  },
  tableCell: {
    width: "20%",
  },
  actionCell: {
    width: "15%",
  },
  addButton: {
    marginTop: "10px",
  },
};
const webStyle = {




  TimemaintableHead: {
    backgroundColor: "#F5F5F5",
  },
  labelStyle10: {
    fontSize: "22px",
    fontFamily: "Open Sans",
    fontWeight: 500,
    color: "black",
    marginTop: '5px'
  },
  Timeheadermandatory: {
    display: "flex",
    MarginTop: "30px",
    alignItems: "baseline",
    gap: "4px",
  },
  TimetablemainContainer: {
    marginTop: "15px",
    borderRadius: "10px",
    border: "1px solid #979797",
  },
  TimeinformationSubContainerTable: {
    marginTop: "24px",
    width: "98%",
  },
  inputmainTable: {
    background: 'transparent',
    border: '0.5px solid #979797',
    width: "70%",
  },
  Editbutton: {
    backgroundColor: "FDD100",
    color: "black",
    fontFamily: 'Open Sans',
    padding: "15px 20px 15px 20px",
    width: "200px",
    fontSize: "16px",
    borderRadius: "10px",
    fontWeight: 600,
    textTransform: "none"
  },

  TimeCancelBtn: {
    fontWeight: 600,
    fontFamily: 'Open Sans',
    textTransform: "none",
    backgroundColor: "white",
    fontSize: "16px",
    width: "170px",
    color: "black",
  },
  informatSubContainerTable: { marginTop: "24px", width: "98%" },
  TimeinformSecondContainerHead: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "24px",
    alignItems: "initial",
    width: "98%"
  },
  TimetopdescThirty: {
    marginTop: "30px",
  },
  container: {
    height: "100%",
    width: "100%",
  },
  titleField: {
    border: "0.25px solid #979797",
    borderRadius: "12px",
    backgroundColor: "white"
  },
  qualifiedResult: {
    width: '70px',
    border: "0.25px solid #979797",
    borderRadius: "12px",
    backgroundColor: "white",
    marginTop: '7px',
  },
  reminderCheklist: {
    width: '250px',
    border: "0.25px solid #979797",
    borderRadius: "12px",
    backgroundColor: "white",
    marginTop: '7px'
  },
  dkrDropdown: {
    padding: '10px',
    border: "none",
    fontSize: "16px",
    fontWeight: 400,
    backgroundColor: "white"
  },
  optionRemoveBtn: {
    width: '100%',
    border: "0.25px solid #FF5C5C",
    fontSize: "16px",
    fontWeight: 400,
    borderRadius: '12px',
    backgroundColor: "white",
  },
  Radio1:{
    color: 'FDD100',
    paddingBottom: '12px'
  },
  Radio2:{
    color: '#00000061',
    paddingBottom: '12px'
  },
  containerslab: {
    padding: "20px 0",
  },
  tableCellslab: {
    width: "20%",
  },
  actionCellslab: {
    width: "15%",
  },
  addButtonslab: {
    marginTop: "10px",
  },
  addBtnslab: {
    border: "1px solid yellow",
    marginLeft: '15px',
    padding: '7px',
    borderRadius: '8px',
  },
  removeBtnslab: {
    border: "1px solid #FF5C5C",
    padding: '7px',
    borderRadius: '8px',
  },
  TextField2:{
    width: '276px',
    marginTop: '10px',
    borderRadius: '10px',
    padding: '8px',
    backgroundColor: 'white',
    border: '0.25px solid #979797',
  },
  Typography2:{
    fontFamily: 'Open Sans', 
    display: 'flex', 
    justifyContent: 'end', 
    color: 'black', 
    fontSize: "larger",
  },
  TimelabelStyleoptionhead: {
    color: "black",
    fontWeight: 500,
    fontSize: "22px",
    fontFamily: "Open Sans"
  },
  TimeTopstyleHeader: {
    fontSize: "18px",
    color: "#c8ccc9",
    fontWeight: 700,
    fontFamily: "Open Sans"
  },
  TimeblurvalueStyle: {
    color: "black",
    marginTop: "10px",
    fontSize: "16px",
    fontFamily: "Open Sans",
    fontWeight: 400,
    textTransform: 'capitalize'
  },
  TimerowarrowDisplay: {
    alignItems: "center",
    display: "flex",
  },
  TimeHeadStyleTop1: {
    color: "black",
    fontFamily: "Open Sans",
    fontSize: "18px",
  },
  TimeHeadtopStyle22: {
    color: "black",
    fontWeight: 700,
    fontSize: "24px",
    fontFamily: "Open Sans"
  },

  TimemarginLeft0: {
    marginLeft: 0,
    marginTop: 10
  },

  TimebuttonmainContainer: { display: "flex", gap: "20px", margin: "0 40px" },

  TimeheaderMain10: {
    display: "flex",
    alignItems: "baseline",
    gap: "4px",
    MarginTop: "30px"
  },
  TimeSubmitBtn: {
    fontFamily: 'Open Sans',
    marginRight: "20px",
    color: "black",
    fontSize: "16px",
    width: "170px",
    backgroundColor: "FDD100",
    fontWeight: 600,
    textTransform: "none",

  },
  disabledInput: {
    '&.MuiInputBase-input.Mui-disabled': {
      textAlign: 'end',
      padding: '5px',
    },
  },
  TableC: {
    fontSize: "14px",
    color: "black",
    fontWeight: 600,
    fontFamily: "Open Sans",
  },

  Timemaincontainer: {
    background: "#F3F4F5",
    height: "64px",
    marginRight: "-20px"
  },
  TimeinformContainer: {
    padding: "0 32px",
    flexDirection: "column",
    alignItems: "baseline",
  },
  TimeinformationSubject: {
    marginTop: "24px",
    width: "800px",
  },
  TimeleftSide: {
    padding: "19px 0px 21px 38px"
  },
  TimenullContainer: {
    padding: "0px 44px 0px 0px"
  },

};
const StyledDivider=styled(Divider)({
  marginTop: "20px", 
  marginBottom: "20px"
})

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-input': {
    textAlign: 'right',  
    padding: '5px 0',
  },
}));
// Customizable Area End
