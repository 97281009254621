import React from "react";

import {
    // Customizable Area Start
    Box, Typography, Button, Grid, Paper, styled, Checkbox, Modal, TextField, InputAdornment
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { arrow_left, arrow_right, cross, ploygon_arrow, Pen } from "./assets";
import Chart from 'react-apexcharts';
import Loader from "../../../components/src/Loader";
import moment from "moment";
import Approval from "../../MultilevelApproval/src/Approval.web";

import {
    DkrDetailsData
} from "./AddDkrDetailsController";
// Customizable Area End

import AddDkrDetailsController, {
    Props
} from "./AddDkrDetailsController";


export default class ViewKpiDetails extends AddDkrDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    RenderArchivedDataCount = ({dkrDetail}: {dkrDetail: DkrDetailsData}) => (
            <PointAchievedContainer style={{marginTop: !dkrDetail.run_rate ? '25px' : 0}}>
                <PointsAchievedTypography >{"Points Achieved"}</PointsAchievedTypography>
                <PointsAchievedContent>{(dkrDetail.employee_submitted_points || 0) + " Points"}</PointsAchievedContent>
            </PointAchievedContainer>
    );

    RenderDKRPoints = ({dkrDetail}: {dkrDetail: DkrDetailsData}) => {
        return (
            <>
                {(!this.checkForApprovePoints(dkrDetail) && this.isAutoSubmission(dkrDetail)) && (this.RenderArchivedDataCount({dkrDetail}))}
                <PointsNewContainer style={{marginTop: 0}}>
                    {dkrDetail.employee_submitted_points ? <PointsAchievedContainer>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                            <Typography style={{ color: '#000000', marginBottom: '8px' }}>Points achieved</Typography>
                            {this.showEditSubmission() && <img className="editIcon" src={Pen} alt='pen icon!' onClick={this.handleOpen} />}
                        </div>
                            <Typography style={{ color: '#000000', fontWeight: 600, fontSize: '24px',overflow:'auto' }}>{dkrDetail.employee_submitted_points} Points</Typography>
                        </PointsAchievedContainer> : null}
                    {this.checkForApprovePoints(dkrDetail) ? <PointsApprovedContainer>
                        <Typography style={{ color: '#000000', marginBottom: '8px' }}>Points approved</Typography>
                        <Typography style={{ color: '#000000', fontWeight: 600, fontSize: '24px' }}>{this.checkForApprovePoints(dkrDetail)} Points</Typography>
                    </PointsApprovedContainer> : null}
                </PointsNewContainer>
            </>
        )
    }
    // Customizable Area End

    render() {
        const { chartData, open, dkrDetail, currentProgress, monthWiseData } = this.state;
        const radius = 50;
        const circumference = 2 * Math.PI * radius;
        const highValueDate = monthWiseData.largest_dkr_created_at ? monthWiseData.largest_dkr_created_at : new Date()
        return (
            // Customizable Area Start
            <>
                <HeaderSection>
                    <img onClick={() => this.backNavigate("GamificationWeb")} data-test-id="NavigateBack" style={{ height: '14px', width: '9px', cursor: 'pointer' }} src={arrow_left} alt="<" />
                    <KPIdetailsTypography variant="h5" >
                        KPI Details</KPIdetailsTypography>
                </HeaderSection>
                <Loader loading={this.state.loading} />
                <ContentSection style={{height: 'calc(100svh - 230px)'}}>
                    <Box style={{ display: 'flex', alignItems: "center", justifyContent: "space-between", marginBottom: '21px' }}>
                        <Box style={{ display: "flex", gap: '32px', alignItems: 'center' }}> <img style={{ height: '14px', width: '10px' }} src={arrow_left} alt="<" />
                            <Typography style={{
                                fontFamily: 'Open Sans',
                                fontSize: '20px',
                                fontWeight: 600,
                                color: '#000000',
                                lineHeight: '20px'
                            }}>March 2024</Typography>
                            <img style={{ height: '14px', width: '10px' }} src={arrow_right} alt=">" />
                        </Box>
                        <Typography style={{
                            fontFamily: 'Open Sans',
                            fontSize: '14px',
                            fontWeight: 700,
                            color: '#000000',
                        }}>Assigned on: {this.kprDate()}</Typography>
                    </Box>
                    <DescriptionSection >
                            <KpiTitle>{dkrDetail.KPI}</KpiTitle>
                            <Grid container spacing={2} justifyContent={"space-between"}>
                                <Grid item xs={12} md={6}>
                                <PointsSection style={{ border: '1px solid #FFCC0066' }}>
                                    {dkrDetail.kpi_desc?.split(/\r\n/).map((line, index) => (
                                        <TypographyStyles key={index}>{line}</TypographyStyles>
                                    ))}
                                    </PointsSection>
                                </Grid>
                                {!!dkrDetail.weightage && (
                                    <Grid item xs={6} md={3}>
                                        <WeightageSection>
                                            <WeightageTypography>Weightage</WeightageTypography>
                                            <PointsTypography>{(dkrDetail.weightage)+" Points"}</PointsTypography>
                                        </WeightageSection>
                                    </Grid>
                                )}
                                {dkrDetail.goal_value !== null && <Grid item xs={6} md={3}>
                                    <GoalSection>
                                        <Typography variant="h6">Goal</Typography>
                                        <Typography variant="h4">{dkrDetail.goal_value}</Typography>
                                    </GoalSection>
                                </Grid>
                                }
                            </Grid>
                        </DescriptionSection>
                    <AnalyticsSection>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                {dkrDetail.calculation_type === null && (
                                    <NoProgressSection>
                                        {dkrDetail.kpi_type === "checklist" && (
                                                <EndMonthAwardConatiner>
                                                    <Typography
                                                        style={{
                                                            fontSize: '14px',
                                                            fontWeight: 400,
                                                            color: '#000000',
                                                            lineHeight: '21.79px',
                                                            fontFamily: "Open Sans",
                                                            fontStyle: "italic",
                                                            textAlign: 'center'
                                                        }}
                                                    >
                                                        {"Points will be awarded at the end of the month"}
                                                    </Typography>
                                                </EndMonthAwardConatiner>
                                            )}
                                         {(dkrDetail.kpi_type === "core" && dkrDetail.run_rate === null) && (
                                        <EndMonthAwardConatiner> <Typography className="coreContentText">
                                                    {"Award points at the end of the month"}
                                        </Typography> </EndMonthAwardConatiner>
                                         )}
                                        {this.isManualAddPointsData() && <>
                                            {this.isAddPointsButton() && <AddPointsContainer onClick={this.handleOpen} data-test-id="openModal"><AddPointsTypography >Add Points</AddPointsTypography></AddPointsContainer>}
                                            {this.RenderDKRPoints({dkrDetail})}
                                        </>}
                                        {dkrDetail.kpi_type === "checklist" && <><ChecklistOtions>Checklist options</ChecklistOtions>
                                            <ChecklistOtionsContainer>
                                                {dkrDetail.options && dkrDetail.options.map((data, index) => {
                                                    return (
                                                        <ChecklistOtionsInnerContainer key={index}>
                                                            <Typography style={{ fontSize: '14px', fontWeight: 400, lineHeight: '24px', color: '#000000' }}>{data.title}</Typography>
                                                            <StyledCheckbox
                                                                value={dkrDetail.DKR_response_type[index]}
                                                                onChange={() => this.handleChangeChecbox}
                                                                data-test-id="checkBox"
                                                            />
                                                        </ChecklistOtionsInnerContainer>
                                                    );
                                                })}
                                            </ChecklistOtionsContainer> </>}
                                     {(dkrDetail.kpi_type === "timing" || dkrDetail.kpi_type === "attendance" || dkrDetail.kpi_type === "report" || dkrDetail.kpi_type === "occurrence") && (
                                        <CurrentProgressSection>
                                        <CurrentProgressTypography >Current Progress</CurrentProgressTypography>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <SalesTrackerContainer > 
                                               {dkrDetail.kpi_type === "attendance" && (
                                               <Typography style={{ color: '#000000', lineHeight: '20px' }} >{"Total Absents"} </Typography>
                                               )} 
                                                {dkrDetail.kpi_type === "timing" && (
                                               <Typography style={{ color: '#000000', lineHeight: '20px' }} >{"Total Late Occurrence"} </Typography>
                                               )} 
                                                {dkrDetail.kpi_type === "report" && (
                                               <Typography style={{ color: '#000000', lineHeight: '20px' }} >{"Total Missed Submission"} </Typography>
                                               )} 
                                                {dkrDetail.kpi_type === "occurrence" && (
                                               <Typography style={{ color: '#000000', lineHeight: '20px' }} >{"Negative Response"} </Typography>
                                               )} 
                                                <Typography style={{ color: '#000000', lineHeight: '20px' }} >{currentProgress.total_dkr_input}</Typography>
                                                </SalesTrackerContainer>
                                            </Grid>
                                            <Grid item xs={6} style={{ display: "flex", justifyContent: "center" }}>
                                                       <ProgressCircleWrapper>
                                                         <img  className="progressImg" src={ploygon_arrow} alt='!' />
                                                            <svg width="120" height="120">
                                                           <CircleBackground r={radius} cx="60" cy="60"/>
                                                          <CircleProgress r={radius} cy="60" cx="60"
                                                             strokeDashoffset={this.state.offset}
                                                             strokeDasharray={circumference}
                                                           />
                                                           </svg>
                                                          <ProgressText>{currentProgress.percentage_achieved}%</ProgressText>
                                                      </ProgressCircleWrapper>
                                                </Grid>
                                        </Grid>
                                        {this.RenderArchivedDataCount({dkrDetail})}
                                    </CurrentProgressSection>
                                     )}
                                    </NoProgressSection>
                                )}
                                {(dkrDetail.calculation_type === "slab" || dkrDetail.calculation_type === "pro_rata") && (
                                    <CurrentProgressSection>
                                        <CurrentProgressTypography >Current Progress</CurrentProgressTypography>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <SalesTrackerContainer > 
                                                <Typography style={{ color: '#000000', lineHeight: '20px' }} >{dkrDetail.options[dkrDetail.options.length-1]?.title} </Typography>
                                                <Typography style={{ color: '#000000', lineHeight: '20px' }} >{currentProgress.total_dkr_input}</Typography>
                                                </SalesTrackerContainer>
                                                {dkrDetail.run_rate === true && (<>
                                                    <SalesTrackerContainer>
                                                        <Typography style={{ color: '#000000', lineHeight: '20px' }} >CRR: </Typography>
                                                        <Typography style={{ color: '#000000', lineHeight: '20px' }} >{currentProgress.current_run_rate} </Typography>
                                                    </SalesTrackerContainer>
                                                    <SalesTrackerContainer style={{ marginBottom: '55px', }} >
                                                        <Typography style={{ color: '#000000', lineHeight: '20px' }} >RRR: </Typography>
                                                        <Typography style={{ color: '#000000', lineHeight: '20px' }} >{currentProgress.required_run_rate}</Typography>
                                                    </SalesTrackerContainer>
                                                </>)}
                                            </Grid>
                                            <Grid item xs={6} style={{ display: "flex", justifyContent: "center" }}>
                                                <ProgressCircleWrapper>
                                                    <img style={{
                                                        position: 'absolute',
                                                        top: '-8px',
                                                        left: '50px',
                                                    }} src={ploygon_arrow} alt='!' />
                                                    <svg width="120" height="120">
                                                        <CircleBackground r={radius} cx="60" cy="60" />
                                                        <CircleProgress
                                                            r={radius}
                                                            cy="60"
                                                            cx="60"
                                                            strokeDasharray={circumference}
                                                            strokeDashoffset={this.state.offset}
                                                            />
                                                    </svg>
                                                    <ProgressText>{currentProgress.percentage_achieved}%</ProgressText>
                                                </ProgressCircleWrapper>
                                            </Grid>
                                        </Grid>
                                        <this.RenderDKRPoints dkrDetail={dkrDetail} />
                                    </CurrentProgressSection>
                                )}
                            </Grid>
                            {dkrDetail.calculation_type !== null && (<Grid item xs={12} md={6}>
                                <DailyPointsSection>
                                    <DailyGoalTypography>
                                        Daily Goal Achievement
                                    </DailyGoalTypography>
                                    <Box>
                                        <Chart options={chartData.options} series={chartData.series} type="area" height={256} />
                                    </Box>
                                    <HighestScoreContainer>
                                        <Typography style={{
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            lineHeight: '20px',
                                            color: '#000000'
                                        }}>
                                            Highest:{' '}
                                            <span style={{
                                                fontSize: '16px',
                                                fontWeight: 700,
                                                lineHeight: '20px',
                                                color: '#000000'
                                            }}>
                                                {monthWiseData.largest_dkr_input}
                                            </span>
                                        </Typography>
                                        <Typography style={{
                                            fontSize: '16px',
                                            fontWeight: 500,
                                            lineHeight: '20px',
                                            color: '#000000'
                                        }}>
                                            {moment(highValueDate, "MMM DD").format("MMMM, D")}
                                        </Typography>
                                    </HighestScoreContainer>
                                </DailyPointsSection>
                            </Grid>)
                            }
                        </Grid>
                    </AnalyticsSection>-
                    {this.getDailySubmissionFlag(dkrDetail.kpi_type) && <ViewButton onClick={()=>this.GoToDailySubmissions("DailySubmissions")} variant="contained" data-test-id="GoToDailySubmissionsId"  >
                        <ViewDailySubmissionsTypography >View Daily Submissions</ViewDailySubmissionsTypography>
                    </ViewButton>}
                    {this.showApproval() && <Approval kpiData={dkrDetail} handleKpiData={this.handleDkrDetailData} GoToDailySubmissions={this.GoToDailySubmissions}/>}
                </ContentSection>
                <Modal open={open} onClose={this.handleClose}>
                    <ModalBox>
                        <Box style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography style={{
                                fontSize: '18px',
                                fontWeight: 500,
                                color: '#000000',
                                lineHeight: '24px',
                            }}>Award this month points for <span style={{ fontWeight: 700 }}>{dkrDetail.KPI}</span></Typography>
                            <CrossButton onClick={this.handleClose}
                                data-test-id="handleClose"><img src={cross} alt='X' /></CrossButton>
                        </Box>
                        <Box style={{ margin: '24px 0 20px 0' }}>
                            <TextField style={{ width: '100%' }}
                                label="Enter point*"
                                type="text"
                                variant="outlined"
                                defaultValue={this.state.dkrDetail.employee_submitted_points || null}
                                onChange={this.handleInputChange}
                                onKeyPress={this.handleNumberInput}
                                data-test-id="handleInputChangeId"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">Points</InputAdornment>
                                }}
                            />
                        </Box>
                        {this.checkInputValidation() && <Typography style={{ color: '#6191C7', fontSize: '13px', fontWeight: 700, marginBottom: '40px' }}>Points can be Min. 0 and Max. of Total Weightage of KPI</Typography>}
                        <SubmitBox disabled={(!this.state.newPointsAchieved || this.checkInputValidation())} onClick={this.handleCloseSubmit} data-test-id="handleCloseSubmitId"><SubmitTypography >Submit</SubmitTypography></SubmitBox>
                    </ModalBox>
                </Modal>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const KPIdetailsTypography = styled(Typography)({
    fontFamily: 'Open Sans',
    fontSize: '18px',
    fontWeight: 400,
    color: '#000000',
    lineHeight: '20px'
});
const TypographyStyles = styled(Box)({
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
});
const ContentSection = styled(Box)({
    padding: '0 50px 0 32px',
});
const HeaderSection = styled(Paper)({
    padding: '36px 32px',
    fontFamily: 'Open Sans',
    marginBottom: '50px',
    background: '#F3F4F5',
    display: 'flex',
    alignItems: 'center',
    gap: '21px',
});
const DescriptionSection = styled(Paper)({
    border: '0.5px solid #FFCC00',
    marginBottom: '25px',
    borderRadius: '8px',
    background: '#FFFDF6',
    padding: '32px 20px 20px 20px',
    boxShadow: '0px 1px 14px 0px #00000011',
});
const KpiTitle = styled(Typography)({
    marginBottom: '33px',
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '20px',
    color: '#000000'
});
const AnalyticsSection = styled(Paper)({
});
const PointsSection = styled(Paper)({
    backgroundColor: '#FFFDF6',
    padding: '16px 29px 27px 16px',
    height: '136px',
    borderRadius: '8px',
});
const DailyPointsSection = styled(Paper)({
    borderRadius: '8px',
})
const WeightageSection = styled(Paper)({
    backgroundColor: '#FFFDF6',
    display: 'flex',
    height: '136px',
    flexDirection: 'column',
    gap: '37px',
    padding: '16px 16px 12px 16px',
    border: '1px solid #FFCC00',
    background: '#FFF9E1',
    borderRadius: '8px',
});
const GoalSection = styled(Paper)({
    backgroundColor: '#FFFDF6',
    display: 'flex',
    height: '136px',
    flexDirection: 'column',
    gap: '37px',
    padding: '16px 16px 12px 16px',
    background: 'linear-gradient(180deg, #FFEDA6 0%, #FFDD55 100%)',
    borderRadius: '8px',
});
const CurrentProgressSection = styled(Paper)({
    background: '#FFFDF6',
    height: '342px',
    border: '0.5px solid #FFCC00',
    marginBottom: '21px',
    padding: '24px 25px 24px 24px',
    borderRadius: '8px',
    boxShadow: '0px 1px 14px 0px #00000011',
});
const NoProgressSection = styled(Paper)({
    // height: '342px',
    marginBottom: '21px',
});
const PointAchievedContainer = styled(Box)({
    background: 'linear-gradient(180deg, #FFEDA6 0%, #FFDD55 100%)',
    padding: '33px 31px 40px 32px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '8px',
    height: '95px',

})
const SalesTrackerContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
    fontSize: '16px',
    fontWeight: 500,
});
const ViewButton = styled(Button)({
    background: '#FFCC00',
    padding: '16px 77px 20px 78px',
    borderRadius: '8px',
    textTransform: 'unset',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: '#FFCC00',
        color: '#000000',
        cursor: 'pointer',
    },
    boxShadow: 'unset',
    position: 'absolute',
    bottom: 10,
});

const ProgressCircleWrapper = styled(Box)({
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "120px",
    height: "120px",
    "& .progressImg": {
        position: 'absolute',
        top: '-8px',
        left: '50px',
    }
});

const CircleBackground = styled("circle")({
    fill: "none",
    stroke: "#ECECEC",
    width: '117px',
    strokeWidth: 10,
    transform: "rotate(-90deg)",
    transformOrigin: "50% 50%",
});

const CircleProgress = styled("circle")({
    fill: "none",
    width: '117px',
    stroke: "#F7B500", // Progress color
    strokeWidth: 10,
    strokeLinecap: "round",
    transition: "stroke-dashoffset 2s ease",
    transform: "rotate(-90deg)",
    transformOrigin: "50% 50%",
    animation: "animateProgress 2s forwards",
});

const ProgressText = styled(Typography)({
    position: "absolute",
    fontFamily: "Open Sans",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "32.68px",
    color: "#000000",
});
const HighestScoreContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px 24px',
    background: '#FFFDF6',
    border: ' 0.5px solid #FFCC00',
    borderRadius: '8px',
    marginBottom: '21px',
    boxShadow: '0px 1px 14px 0px #00000011',
});
const EndMonthAwardConatiner = styled(Box)({
    background: 'linear-gradient(0deg, #FFFDF6 -27.73%, #FFD630 100%, #FFCC00 100%)',
    padding: '16px 24px',
    maxWidth: '386px',
    borderRadius: '8px',
    marginBottom: "24px",
    "& .coreContentText": {
        fontSize: '16px',
        fontWeight: 600,
        color: '#000000',
        lineHeight: '21.79px',
        fontFamily: "Open Sans",
    }
});
const ChecklistOtions = styled(Box)({
    fontSize: "17px",
    fontWeight: 700,
    lineHeight: '20px',
    marginBottom: '12px',
})
const ChecklistOtionsContainer = styled(Box)({
    padding: '16px 16px 20px 16px',
    boxShadow: '0px 1px 14px 0px #00000011',
    border: '0.5px solid #0000003E',
    borderRadius: '8px',
    maxWidth: '327px',
    width: '100%',
})
const ChecklistOtionsInnerContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '14px',
    height: '24px'
})
const StyledCheckbox = styled(Checkbox)({
    height: '14px',
    width: '14px',
    borderRadius: 2,
    border: '1px solid #0000001A',
    overflow: 'hidden',
    '& .MuiSvgIcon-root': {
        fill: 'transparent'
    },
    '&.MuiCheckbox-colorSecondary.Mui-checked': {
        background: 'black',
        borderRadius: '2px',
        border: ' 1px solid #0000001A',
        '& svg': {
            fill: '#FFCC00',
        }
    }
})
const AddPointsContainer = styled(Box)({
    padding: '16px 125px 20px 125px',
    border: '1px solid #FFCC00',
    borderRadius: '8px',
    maxHeight: '56px',
    width: '350px',
    cursor: 'pointer',
})
const PointsNewContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '500px',
    gap: '16px',
})
const PointsAchievedContainer = styled(Box)({
    borderRadius: '8px',
    cursor: "pointer",
    border: '1px solid #FFCC00',
    background: '#FFF9E1',
    padding: '16px 20px 16px 16px',

    width:'100%',
    maxWidth: '240px',
    "& .editIcon":{
        marginTop: '6px',
        height: '16px',
        width: '16px',
        background: '#FFF',
        borderRadius: '25%'
    }
})
const PointsApprovedContainer = styled(Box)({
    cursor: "pointer",
    background: 'linear-gradient(180deg, #FFEDA6 0%, #FFDD55 100%)',
    borderRadius: '8px',
    padding: '16px 20px 16px 16px',
    width:'100%',
    maxWidth: '240px',
})
const WeightageTypography = styled(Typography)({
    fontSize: '20px', fontWeight: 500, color: '#000000', lineHeight: '27px',
})
const PointsTypography = styled(Typography)({
    fontSize: '32px', fontWeight: 600, color: '#000000', lineHeight: '43px'
})
const ModalBox = styled(Box)({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '410px',
    backgroundColor: 'white',
    padding: '24px 20px',
    borderRadius: '8px',
    '& .MuiButton-root:hover': {
        background: '#FFCC00'
    },
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
        display: "none",
    },
    "& input[type=number]": {
        MozAppearance: "textfield",
    },
})
const DailyGoalTypography = styled(Typography)({
    fontSize: '19px',
    fontWeight: 700,
    lineHeight: '20px',
    color: '#000000'
})
const CurrentProgressTypography = styled(Typography)({
    fontSize: 20,
    fontWeight: 700,
    lineHeight: '20px',
    color: '#000000',
    marginBottom: '24px',
})
const SubmitBox = styled(Button)({
    width: '100%',
    background: '#FFCC00',
    padding: '14px',
    display: 'flex',
    borderRadius: '8px',
    justifyContent: 'center',
    cursor: 'pointer',
})
const PointsAchievedTypography = styled(Box)({
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '21.79px',
    color: '#000000',
})

const PointsAchievedContent = styled(Box)({
    fontSize: '24px !important',
    fontWeight: 600,
    lineHeight: '32.68px !important',
    color: '#000000 !important'
})

const SubmitTypography = styled(Typography)({
    color: '#000000', fontWeight: 600, fontSize: '16px', cursor: 'pointer',
    textTransform: 'none'
})
const ViewDailySubmissionsTypography = styled(Typography)({
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '20px',
    color: '#000000'
})
const CrossButton = styled(Button)({
    background: '#EBEBEB', padding: '11px', borderRadius: '6px',
    minWidth: '40px'
})
const AddPointsTypography = styled(Typography)({
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '20px',
    color: '#000000'
})
// Customizable Area End
