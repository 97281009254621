import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
import ToastNotification from "../../../components/src/ToastNotification.web";
import moment from "moment";

export interface DkrSubmissions {
  date: string,
  report: string,
  attendance: string,
  timing: string
}

export interface KpiValidResponseType {
  data: object;
  errors: Array<KpiErrorPayloadType>;
}
export interface KpiErrorPayloadType {
  key: string;
  message: string;
}
export interface KpiErrorResponseType {
  errors: string;
}
export interface KpiInvalidResponseType {
  errors: Array<string>;
}

export interface KpiDailyDetailsData {
  id: string,
  type: string,
  attributes: {
    id: number,
    name: string,
    title: string,
    description: string,
    status: string,
    department_id: number,
    kpis: KpisDailyDetails
  }
}

export interface KpiDailySubmission {
  data: KpiDailyDetailsData
}

export interface KpisDailyDetails {
  Core: Array<DailySubmissionData>,
  Productivity: Array<DailySubmissionData>,
  Checklist: Array<DailySubmissionData>,
  Attendance: Array<DailySubmissionData>,
  Timing: Array<DailySubmissionData>,
  Occurrence: Array<DailySubmissionData>  
  Report: Array<DailySubmissionData>
}

export interface DailySubmissionData {
  kpi_id: number,
  kpi_desc: string,
  KPI: string,
  kpi_type: string,
  weightage: number,
  goal_value: number,
  goal_type: string,
  DKR_Title: Array<string>,
  DKR_Title_value: Array<string>,
  options: Array<object>,
  DKR_description: null,
  DKR_response_type: Array<string>,
  calculation_type: string,
  minimum_qualify_value: string,
  applicable_occurrences: Array<string>,
  app_occu_count: number
}

export interface DailySubmission {
  app_comm: Array<DailySubmissionData>
}

export interface DailySubmissionData {
  applicable_dkr_id: number,
  applicable_dkr_title: string,
  comments: Array<DailySubmissionComment>
}

export interface DailySubmissionComment {
  title: string,
  dkr_input: string,
  remark: string,
  commentable_type: string
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes:any
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  options:any;
  department:string;
  listOfCards:any;
  selectedId:any;
  openConfirmationModal:boolean;
  toggle:boolean;
  submissions: Array<DkrSubmissions>;
  storedLabel: string;
  dkrDetailsData:Array<DailySubmissionData>;
  dkrDetail: DailySubmissionData;
  selectedDate: Date;
  view: string | unknown;
  submissionData: Array<DailySubmissionData>
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TaskListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDkrDetailDataApiCallId: string | null = null;
  getDailySubmissionDataApiCallId: string | null = null;
  getDkrDetailsApiCallId: string | null  = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      options:[
        {label:'Option1', value:'Option1'},
        {label:'Option2', value:'Option2'},
        {label:'Option3', value:'Option3'},
      ],
      department:'All Departments',
      listOfCards:[
        {
          id:1,
          title: 'Card 1',
          description: 'This is the description for Card 1.',
          buttonText: 'Duplicate'
        },
        {
          id:2,
          title: 'Card 2',
          description: 'This is the description for Card 2.',
          buttonText: 'Duplicate'
        },
        {
          id:3,
          title: 'Card 3',
          description: 'This is the description for Card 3.',
          buttonText: 'Duplicate'
        }
      ],
      selectedId:null,
      openConfirmationModal:false,
      toggle:false,
      submissions : [
        { date: "01-03-2024", report: "Submitted", attendance: "Present", timing: "On Time" },
        { date: "02-03-2024", report: "Submitted", attendance: "Present", timing: "On Time" },
        { date: "03-03-2024", report: "Missed", attendance: "Absent", timing: "Late" },
        { date: "04-03-2024", report: "Submitted", attendance: "Present", timing: "On Time" },
        { date: "05-03-2024", report: "Missed", attendance: "Absent", timing: "Late" },
        { date: "06-03-2024", report: "Submitted", attendance: "Present", timing: "On Time" },
     ],
     storedLabel: "KPI 1",
     dkrDetailsData: [],
     dkrDetail: {} as DailySubmissionData,
     selectedDate: new Date(),
     view: "month",
     submissionData : []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (this.isValidResponseController(responseJson)) {
        this.apiSuccessCallBacksController(apiRequestCallId, responseJson);
      }
      if (this.isInValidResponseController(responseJson)) {
        this.apiFailureCallBacksController(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.handleDkrDetailsData();
    this.handleDailySubmissionData();
    this.handleDkrDetail();
    // Customizable Area End
  }

  handleDateClick = (date: Date) => {
    this.setState({ selectedDate: date }, () => this.handleDailySubmissionData());
  };

  tileDisabled = ({ date }: { date: Date }) => {
    return date > new Date();
  };

  activeStartDateChange = ({ activeStartDate }: { activeStartDate: Date }) => {
    const today = new Date();
    return activeStartDate <= today;
  };

  isValidResponseController = (responseJson: KpiValidResponseType) => {
    return responseJson && !responseJson.errors;
  };

  isInValidResponseController = (responseJson: KpiInvalidResponseType) => {
    return responseJson && responseJson.errors;
  };

  apiSuccessCallBacksController = (apiRequestCallId: string, responseJson: KpiDailySubmission & DailySubmission) => {
    if (apiRequestCallId === this.getDkrDetailDataApiCallId) {
      this.getDkrDetailsDataSuccessCallBack(responseJson)
    }
    if (apiRequestCallId === this.getDailySubmissionDataApiCallId) {
      this.getDailySubmissionDataSuccessCallBack(responseJson)
    }
    if (apiRequestCallId === this.getDkrDetailsApiCallId) {
      this.getDkrDetailSuccessCallBack(responseJson)
    }
  };

  apiFailureCallBacksController = (apiRequestCallId: string, responseJson: KpiInvalidResponseType) => {
    if (apiRequestCallId === this.getDkrDetailDataApiCallId) {
      this.getDkrDetailsDataFailurCallBack(responseJson)
    }
    if (apiRequestCallId === this.getDailySubmissionDataApiCallId) {
      this.getDailySubmissionDataFailurCallBack(responseJson)
    }
    if (apiRequestCallId === this.getDkrDetailsApiCallId) {
      this.getDkrDetailFailurCallBack(responseJson)
    }
  };

  handleDepartmentChange=(value:any)=>{
    console.log('>>>>',value);
    
  }

  handleOpenModalWithId=(id:any)=>{
    this.setState({openConfirmationModal:!this.state.openConfirmationModal,selectedId:id})
  }
  handleOpenModal=()=>{
    this.setState({openConfirmationModal:!this.state.openConfirmationModal})
  }
  handleDuplicateClick=()=>{
    const { listOfCards,selectedId } = this.state;
    const itemToDuplicate = listOfCards.find((item:any) => item.id === selectedId );
    
    // Ensure itemToDuplicate exists
    if (!itemToDuplicate) return;

    // Create a copy of the item with a new ID
    const duplicatedItem = {
      ...itemToDuplicate,
      id: Math.max(...listOfCards.map((item:any) => item.id)) + 1
    };

    // Update state with the duplicated item
    this.setState({
      listOfCards: [...listOfCards, duplicatedItem]

    });
    this.handleOpenModal()
  }

  dailySubmissionApiCall = async (valueData: {
      contentType?: string;
      method?: string;
      endPoint?: string;
      body?: {};
      apiType?: string;
      type?: string;
   }) => {
      let { contentType, method, endPoint, body } = valueData;
      const token = (await getStorageData("authToken")) || "";
      let header = {
        "Content-Type": contentType,
        token,
      };
       let requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        method
      );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
      requestMessage.addData(
       getName(MessageEnum.RestAPIRequestHeaderMessage),
       JSON.stringify(header)
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return requestMessage.messageId;
    };

  backNavigate = (pathChange: string) => {
     const toNavigation: Message = new Message(
        getName(MessageEnum.NavigationMessage)
      );
      toNavigation.addData(getName(MessageEnum.NavigationTargetMessage), pathChange);
      toNavigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(toNavigation);
   };

  handleMonthWeek = (event : React.ChangeEvent<HTMLSelectElement>) =>{
    this.setState({view : event.target.value})
  }

  handleDkrDetails = async () => {
        const kpiId = await getStorageData("selectedLabel");
        const { dkrDetailsData } = this.state;
        const dkrDetail = dkrDetailsData.find(item => item.kpi_id === Number(kpiId));
      if (dkrDetail) {
        this.setState({ dkrDetail: dkrDetail });
      }
    };

  handleDkrDetailsData = async () => {
    this.getDkrDetailDataApiCallId = await this.dailySubmissionApiCall({
       contentType: configJSON.validationApiContentType,
       method: configJSON.validationApiMethodType,
       endPoint: configJSON.getDkrDetailsApiEndPoint,
    });
  };

  getDkrDetailsDataSuccessCallBack = (responseJson: KpiDailySubmission) => {
    if(responseJson){
       const kpis = responseJson.data.attributes.kpis
       const allKpis = Object.values(kpis).flat();
       this.setState({ dkrDetailsData: allKpis},
          ()=> this.handleDkrDetails())
     }
  }

  getDkrDetailsDataFailurCallBack = (responseJson: KpiInvalidResponseType) => {
      ToastNotification.showError(responseJson.errors[0]);
  }

  handleDkrDetail = async () => {
    this.getDkrDetailsApiCallId = await this.dailySubmissionApiCall({
       contentType: configJSON.validationApiContentType,
       method: configJSON.validationApiMethodType,
       endPoint: configJSON.getDkrDetailsApiEndPoint,
    });
  };

  getDkrDetailSuccessCallBack = (responseJson: KpiDailySubmission) => {
    if(responseJson){
      const kpiData = responseJson.data.attributes.kpis
      const allKpi = Object.values(kpiData).flat();
      this.setState({ dkrDetailsData: allKpi},
         ()=> this.handleDkrDetails())
    }
  }

  getDkrDetailFailurCallBack = (responseJson: KpiInvalidResponseType) => {
      ToastNotification.showError(responseJson.errors[0]);
  }

  handleDailySubmissionData = async () => {
     const kpisId = await getStorageData("selectedLabel");
     const { selectedDate } = this.state;
     const modifyDates = moment(selectedDate).format("YYYY-MM-DD");
    this.getDailySubmissionDataApiCallId = await this.dailySubmissionApiCall({
       contentType: configJSON.validationApiContentType,
       method: configJSON.validationApiMethodType,
       endPoint: `${configJSON.getDailySubmissionApiEndPoint}?date=${modifyDates}&kpi_id=${kpisId}`,
    });
  };

  getDailySubmissionDataSuccessCallBack = (responseJson: DailySubmission) => {
     if(responseJson){
       this.setState({ submissionData : responseJson.app_comm })
     } 
  }

  getDailySubmissionDataFailurCallBack = (responseJson: KpiInvalidResponseType) => {
     ToastNotification.showError(responseJson.errors[0]);
   }
  // Customizable Area End
}
