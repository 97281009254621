export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const active_filter = require("../assets/active_filter.png");
export const inActive_filter = require("../assets/inactive_filter.png");
export const fileNotFound = require("../assets/file-not-found.png");
export const success_event = require("../assets/successevent.png");
export const arrow_left = require("../assets/arrow-left.png");
export const message_dots = require("../assets/message_dots.png");
export const info_square=require("../assets/info_Square.png");
export const info_sqaure_bold=require("../assets/info_Square_Bold.png");
export const cross=require("../assets/cross.png");
export const ploygon_arrow=require("../assets/pyramid.png");
export const arrow_right = require("../assets/arrow-right-gray.png")
export const Leaderboard=require("../assets/Leaderboard.png")
export const Scoreboard=require("../assets/Scoreboard.png")
export const Infoboard=require("../assets/Infoboard.png")
export const leader_icon=require("../assets/leader_icon.png")
export const Rectangle=require("../assets/Rectangle.png")
export const Polygon=require("../assets/Polygon.png")
export const Medallion=require("../assets/medallion.png")
export const pngegg=require("../assets/pngegg.png")
export const Calendar_Icon=require("../assets/Calendar.png")
export const Comment_Icon=require("../assets/comment.png")
export const close_Icon=require("../assets/close.png")
export const goal_Icon=require("../assets/goal.png")
export const MessageBox =require("../assets/messageBox.png")
export const Empty =require("../assets/Empty.png");
export const Pen =require("../assets/edit.png");