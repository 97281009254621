Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "TaskList";
exports.labelBodyText = "TaskList Body";

exports.btnExampleTitle = "CLICK ME";

exports.chooseDepartment="Choose Department";
exports.compulsory="*";
exports.allDepartments="All Departments";
exports.allTemplates="All Templates";
exports.noRecord="No Records Found !";
exports.subNoRecord="There are no records to display right now.";
exports.duplicate="Duplicate";
exports.confirmation="Are you really want to duplicate";
exports.yes="Yes";
exports.no="No";
exports.getDkrDetailsApiEndPoint="bx_block_gamification/kpis/kpi_with_dkr";
exports.getDailySubmissionApiEndPoint="bx_block_gamification/comments/date_wise_comment";
// Customizable Area End