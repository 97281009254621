import React from "react";
// Customizable Area Start
import {
  Button,
  Typography,
  Box,
  IconButton,
  Dialog,
  TextField,
  MenuItem, Link, Popover, Grid,
  CircularProgress, InputAdornment
} from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import { createTheme, styled } from "@material-ui/core/styles";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';



// Customizable Area End
import EditTemplate2Controller, { Props } from "./EditTemplate2Controller";
import { GroupIcon } from "./assets";
export const theme = createTheme({
  palette: {
    primary: {
      contrastText: "#fff",
      main: "#fff",
    }
  },
  typography: {
    subtitle1: {
      margin: "20px 0px"
    },
    h6: {
      fontWeight: 500
    },
  },
  overrides: {
    MuiIconButton: {
      root: {
        disableRipple: true,
        '&:hover': {
          backgroundColor: 'transparent',
        }
      }
    }
  }
});
export default class EditTemplate2 extends EditTemplate2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  UpdateKpiDetailCard = () => {
    return (
      <Dialog
        open={this.state.isduplicateOpen}
        onClose={this.handleClose2}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        BackdropProps={{
          style: { backgroundColor: 'rgba(0, 0, 0, 0.3)', boxShadow: 'none' },
        }}
        PaperProps={{
          style: { boxShadow: 'none' },
          elevation: 0,
        }}
      >
        <StyledModelInnerContainer>
          <Box
            style={{
              width: 490,
              boxShadow: 'none',
              backgroundColor: 'white',
              borderRadius: '8px',
              padding: '30px',
            }}
          >
            <Box style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
              <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <Typography
                  id="dialog-title"
                  style={{
                    fontSize: 22,
                    color: 'black',
                    display: 'flex',
                    fontWeight: 500,
                    justifyContent: 'center',
                  }}
                >
                  Do you really want to update KPI Details?
                </Typography>
                <Typography
                  id="dialog-description"
                  style={{
                    fontSize: 18,
                    color: 'black',
                    lineHeight: 2.5,
                    justifyContent: 'center',
                    display: 'flex',
                    fontWeight: 400
                  }}
                >
                  Updating KPI Details will remove the DKRs
                </Typography>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'row',
                  paddingRight: '40px',
                  paddingLeft: '40px',
                  marginTop: '22px',
                }}
              >
                <Button
                  data-test-id="addKpi"
                  style={{
                    color: 'black',
                    marginRight: '12px',
                    width: '80px',
                    fontSize: '12px',
                    textTransform: 'none',
                    backgroundColor: '#FDD100',
                    fontWeight: 600,
                    fontFamily: "Open Sans"
                  }}
                  onClick={this.navigateToSignUp.bind(this)}
                >
                  Yes
                </Button>
                <Button
                  variant="outlined"
                  style={{
                    backgroundColor: 'white',
                    fontWeight: 600,
                    color: 'black',
                    width: '80px',
                    fontFamily: "Open Sans",
                    fontSize: '12px',
                    textTransform: 'none',
                  }}
                  data-testid="hhh"
                  onClick={this.handleClose2}
                >
                  No
                </Button>
              </Box>
            </Box>
          </Box>
        </StyledModelInnerContainer>
      </Dialog>


    )
  };
  render() {
    // Customizable Area Start

    const { templateState } = this.state
    const data = ["added", "approved"];
    const isAdded = data.includes(templateState);
    const breadcrumbs = [
      <Link
        key="1"
        href="/EditTemplate2"
      >
        <Typography style={{ color: 'black', fontSize: "18px", fontWeight: 701, fontFamily: 'Open Sans', }}>{isAdded ? "View Template" : "Edit Template"}</Typography>
      </Link>,
    ];
    // Customizable Area End
    // istanbul ignore next
    return (
      // Customizable Area Start
      <>
        {this.state.circularLoading ? (
          <CircularProgressBox>
            <StyledCircularProgress />
          </CircularProgressBox>
        ) : (
          <Box>
            <StyledForm >
              <div style={webStyle.filterHeader}>
                <Breadcrumbs separator="" aria-label="breadcrumb" data-test-id="addKpi">
                  <div>
                    <IconButton style={{ color: "black", fontSize: "small", padding: "0px", background: "none" }} data-test-id="nextButton" onClick={this.navigateToTemplateAdded}>
                      <ArrowBackIosIcon />
                    </IconButton>
                  </div>
                  {breadcrumbs}
                </Breadcrumbs>
              </div>
            </StyledForm>

            <Box>
              <StyledHeaderBox>
                <NameParentBox >
                  <TemplateNameTypography >
                    Template Name
                  </TemplateNameTypography>
                  <StyledNameTextField
                    hiddenLabel
                    disabled={isAdded}
                    data-test-id="descriptionId"
                    value={this.state.templateKpiDetails?.name}
                    multiline
                    InputProps={{ disableUnderline: true }}
                    onChange={this.handleNameChange}
                  />
                </NameParentBox>
                <Box style={{ flex: 1, }} >
                  <TemplateDescriptionTypography >
                    Template Description
                  </TemplateDescriptionTypography>

                  <StyledDescriptionTextField
                    data-test-id={`description`}
                    disabled={isAdded}
                    value={this.state.templateKpiDetails?.description}
                    rows={6}
                    multiline
                    InputProps={{ disableUnderline: true }}
                    fullWidth
                    onChange={this.handleDescriptionChange}
                  />
                </Box>
              </StyledHeaderBox>
              <Box sx={webStyle.kpiHeader}>
                <Typography style={webStyle.kpiListText}>
                  KPI List
                </Typography>
                <AddKpiBtn
                  data-test-id="addKpi"
                  onClick={this.addNewkpi}
                  style={{ display: isAdded ? "none" : "block" }}>
                  Add KPI
                </AddKpiBtn>
              </Box>

              <StyledModalContainer>
                <TotalWeightageBox sx={webStyle.totalWeightageContainer}>
                  <Box style={webStyle.weightageCircle}>
                    <Typography style={webStyle.weightageText}>
                      {this.state.templateKpiDetails?.totalWheightage}%
                    </Typography>
                  </Box>
                  <Box>
                    <Typography style={webStyle.totalWeightageText}>
                      Total Weightage
                    </Typography>
                    <StyledModalPara>
                      <p style={webStyle.weightageDescription}>
                        This Weightage Is The Total Sum Of All The KPIS Available In A Template, And Is Affected On Addition And Reduction Of KPI. This Weightage Is The Total Sum Of All The KPIS Available In A Template
                      </p>
                      <p style={webStyle.weightageNote}>
                        Total Always Needs To Be 100.
                      </p>
                    </StyledModalPara>
                  </Box>
                </TotalWeightageBox>
              </StyledModalContainer>


              {Object.entries(this.state.groupTemplates).map(([category, items]: any) => (
                <Box key={category} style={{ marginBottom: "20px" }}>
                  <Typography variant="h5" style={webStyle.coreText}>{category}</Typography>
                  {items.map((item: any) =>(
                    <KpiDetailsBox key={item.id}>
                      <Grid container style={{flexWrap:'nowrap'}}>
                        <Grid item md={2}>
                          <Box style={{ marginLeft: "25px" }}>
                            <Typography style={{ fontSize: "21px", fontWeight: 700, color: '#000000' }}>KPI Name</Typography>
                            <TextField
                              value={item.name}
                              disabled
                              InputProps={{ disableUnderline: true }}
                              style={{
                                backgroundColor: "#F3F4F5",
                                width: "98%",
                                marginTop: "12px",
                                padding: "10px",
                                borderRadius: "10px",
                              }}
                            />
                          </Box>
                        </Grid>
                        <Grid item md={3}>
                          <Box style={{ marginLeft: "25px" }}>
                            <KPIDescriptionTypography>KPI Description</KPIDescriptionTypography>
                            <TextField
                              value={item.description}
                              disabled
                              multiline
                              rows={8}
                              InputProps={{ disableUnderline: true }}
                              style={{
                                width: "100%",
                                marginTop: "12px",
                                padding: "12px",
                                backgroundColor: "#F3F4F5",
                                borderRadius: "10px",
                              }}
                            />
                          </Box>
                        </Grid>
                        <Grid item md={2}>
                          <Box style={{ marginLeft: "30px" }}>
                            <NewWeightageTypography >Weightage</NewWeightageTypography>
                            <TextField
                              disabled
                              InputProps={{
                                disableUnderline: true,
                                startAdornment: (
                                  <InputAdornment position="end">
                                    <StyledInputBox      
                                    >
                                      {item.weightage} Points
                                    </StyledInputBox>
                                  </InputAdornment>
                                )
                              }}
                              style={{
                                backgroundColor: "#F3F4F5",
                                width: "90%",
                                marginTop: "12px",
                                padding: "10px",
                                borderRadius: "10px",
                               
                              }}
                            />
                          </Box>
                        </Grid>
                        <Grid item md={2}>
                          <GoalCalculationBox style={{ marginRight: "-8px" }}>
                            <GoalCalculationTypography>Goal Calculation</GoalCalculationTypography>
                            <TextField
                              value={item.goal_calculation}
                              disabled
                              multiline
                              InputProps={{ disableUnderline: true }}
                              style={{
                                marginTop: "12px",
                                backgroundColor: "#F3F4F5",
                                width: "105%",
                                padding: "10px",
                                borderRadius: "10px",
                              }}
                            />
                          </GoalCalculationBox>
                        </Grid>
                        <NewStyledGrid item md={3}>
                          <NewButtonBox >
                            <Button
                              style={{
                                backgroundColor: item.status === "Ready" ? "rgba(145, 255, 163, 0.2)" : "#dcdfff",
                                padding: "9px",
                                color: item.status === "Ready" ? "#1FA625" : "#7d7dde",
                                borderRadius: "13px",
                                fontWeight: 400,
                                width: "120px",
                                textTransform: "none",
                                border: item.status === "Ready" ? "1px solid rgba(101, 174, 99, 0.5)" : "1px solid #a5a8ff",
                                marginTop: "12px",
                                fontSize: "18px",
                                cursor: 'default'
                              }}
                            >
                              {item.status}
                            </Button>
                          </NewButtonBox>
                          </NewStyledGrid>
                    <StyledMenuBox >
                      <IconButton onClick={(event)=>this.handleClick7(event ,item.id, item.status)}>
                        <img src={GroupIcon} alt="group" style={{ fontSize: "40px" }} />
                      </IconButton>
                      <Popover
                        open={Boolean(this.state.anchorEl7)}
                        anchorEl={this.state.anchorEl7}
                        onClose={this.handleClickClose7}
                        anchorOrigin={{
                          horizontal: "right",
                          vertical: "bottom",
                        }}
                        transformOrigin={{
                          horizontal: "right",
                          vertical: "top",
                        }}
                        PaperProps={{
                          style: {
                            padding: "10px",
                            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
                            borderRadius: "10px",
                          },
                        }}
                      >
                        <Box>
                          <MenuItem style={{ backgroundColor: "#FDD001", color: "black" }} onClick={()=>this.navigateToTimingView(item.id)}>
                            View Detailed KPI
                          </MenuItem>
                          <MenuItem disabled={isAdded} onClick={this.state.itemStatus === "Ready" ? this.toggleModal : this.navigateToSignUp.bind(this)}>Edit KPI Details</MenuItem>
                          <MenuItem disabled={isAdded} style={{ color: "black" }} onClick={this.navigateToTimingDKR}>
                            Add DKR Details
                          </MenuItem>
                          <MenuItem disabled={isAdded}  style={{ color: "black" }} onClick={this.deleteNewKpi}>Remove</MenuItem>
                        </Box>
                      </Popover>
                    </StyledMenuBox>
                  </Grid>
              </KpiDetailsBox>
            ))}
          </Box>
        ))}
          <Box style={{ marginTop: '50px', marginLeft: '50px', display: isAdded? "none" : "block" }}>
            <StyledSubmitButton data-test-id="submitKpi"  onClick={this.submitEditData}>
              Submit
            </StyledSubmitButton>
            <Button variant="outlined"
            data-test-id="handleBackTagTemplateId"
            onClick={()=>this.handleBackTagTemplate('TemplateAdded')} style={webStyle.cancelButton as React.CSSProperties}>
              Cancel
            </Button>
          </Box>
        </Box>
        {this.UpdateKpiDetailCard()}
        </Box>
      )}
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {
  templateNameContainer: {

  },
  templateNameText: {

  },
  templateNameInput: {

  },
  descriptionInput: {

  },
  kpiHeader: {
    display: "flex",
    height: "70px",
    flexDirection: "row",
    backgroundColor: '#F3F4F5',
    justifyContent: "space-between",
    marginTop: '20px',
  },
  kpiListText: {
    color: 'black',
    fontWeight: 700,
    fontSize: "18px",
    marginLeft: '20px',
    fontFamily: 'Open Sans',
    marginTop: '20px',
  },
  addKpiButton: {
    marginBottom: "10px",
    marginTop: "10px",
    backgroundColor: "#FDD100",
    marginRight: "20px",
    color: "black",
    width: "130px",
    fontWeight: 700,
    fontSize: "16px",
    textTransform: 'none',
    fontFamily: 'Open Sans',
  },
  totalWeightageContainer: {

  },
  weightageCircle: {
    height: "70px",
    minWidth: "70px",
    borderRadius: "50%",
    border: "5px solid #fdd100",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "25px",
  },
  weightageText: {
    fontWeight: 700,
    color: 'black',
    fontFamily: 'Open Sans',
    fontSize: "large",
  },
  totalWeightageText: {
    fontWeight: 700,
    fontSize: "28px  ",
    color: 'black',
    fontFamily: 'Open Sans',
    marginLeft: '20px',
  },
  weightageDescription: {
    fontWeight: 250,
    color: 'black',
    fontFamily: 'Open Sans',
    fontSize: "16px",
    marginLeft: '20px',
    marginTop: '20px',
  },
  weightageNote: {
    fontWeight: 250,
    color: 'black',
    fontFamily: 'Open Sans',
    fontSize: "16px",
    lineHeight: '1.5',
    marginLeft: '20px',
  },
  coreText: {
    color: 'black',
    fontWeight: 700,
    fontFamily: 'Open Sans',
    fontSize: "24px",
    marginLeft: '55px',
    marginTop: '35px',
    marginBottom: "-30px"
  },

  cancelButton: {
    fontFamily: 'Open Sans',
    color: "black",
    backgroundColor: "white",
    fontSize: "medium",
    width: "170px",
    fontWeight: 550,
    textTransform: 'none'
  },

  template: {
    display: "flex",
    flexWrap: "wrap",
    padding: "10px 10px",
    backgroundColor: '#F3F4F5',
    position: "relative",
    height: "72px",
    alignItems: "center"
  },
  filterHeader: {
    padding: "0px 0px 0px 22px",
    display: "flex",
    alignItems: "flex-start",
    gap: "20px"
  },
};

const StyledModalContainer = styled(Box)({
  '@media (max-width: 1370px)': {
    width: 'fit-content',
  },
})

const StyledModalPara = styled(Box)({
  '@media (max-width: 1370px)': {
    fontSize: "small",
  },
})
const StyledModelInnerContainer = styled(Box)({
  '&.MuiPaper-elevation24': {
    boxShadow: "none",
  }
});
const WeightageTypography = styled(Typography)({
  color: 'black',
  fontWeight: 700,
  fontFamily: 'Open Sans',
  fontSize: "21px",
})
const StyledForm = styled('form')({
  display: "flex",
  flexWrap: "wrap",
  padding: "10px 10px",
  backgroundColor: '#F3F4F5',
  position: "relative",
  height: "72px",
  alignItems: "center",
  '@media (max-width: 576px)': {
    marginTop: '35px',
  },
});
const StyledHeaderBox = styled(Box)({
  padding: '0px 45px 0px 36px',
  marginTop: '20px',
  display: 'flex',
  gap: '32px',
  '@media (max-width: 800px)': {
    flexDirection: 'column',
    gap: '15px',
  },
  '@media (max-width: 600px)': {
    padding: '0px 18px 0px 18px',
  },

})
const TemplateNameTypography = styled(Typography)({
  fontWeight: 700,
  fontFamily: 'Open Sans',
  color: 'black',
  fontSize: "18px",
});

const TemplateDescriptionTypography = styled(Typography)({
  fontWeight: 700,
  fontFamily: 'Open Sans',
  color: 'black',
  fontSize: "18px",
});
const StyledNameTextField = styled(TextField)({
  backgroundColor: '#F3F4F5',
  border: 'none',
  height: 'auto',
  minWidth: '470px',
  width: '100%',
  borderRadius: '10px',
  padding: '8px',
  marginTop: '15px',
  '@media (max-width: 1100px)': {
    minWidth: '70%',
  },
  '@media (max-width: 800px)': {
    maxWidth: '100%',
  },
})
const StyledDescriptionTextField = styled(TextField)({
  marginTop: '15px',
  border: 'none',
  backgroundColor: '#F3F4F5',
  borderRadius: '10px',
  padding: '8px',

})
const NameParentBox = styled(Box)({
})
const TotalWeightageBox = styled(Box)({
  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
  backgroundColor: 'white',
  padding: "25px 15px 25px 15px",
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: "12px",
  flexDirection: 'row',
  marginLeft: '40px',
  marginTop: '50px',
  marginRight: '50px',
  width: 'auto',
  '@media (max-width: 600px)': {
    flexDirection: 'column',
    marginLeft: '20px',
    marginRight: '20px',
  }
})
const KpiDetailsBox = styled(Box)({
  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
  backgroundColor: 'white',
  borderRadius: "12px",
  display: 'flex',
  flexDirection: 'row',
  padding: "30px 15px",
  marginLeft: '50px',
  width: 'auto',
  marginTop: '50px',
  marginRight: '65px',
  '@media (max-width: 600px)': {
    flexDirection: 'column',
    marginLeft: '20px',
    marginRight: '20px',
  }
})
const GoalCalculationBox = styled(Box)({
  '@media (max-width: 600px)': {
    marginLeft: '25px',
  }
})
const AddKpiBtn = styled(Button)({
  marginBottom: "10px",
  marginTop: "10px",
  backgroundColor: "#FDD100",
  marginRight: "20px",
  color: "black",
  width: "130px",
  fontWeight: 700,
  fontSize: "16px",
  textTransform: 'none',
  fontFamily: 'Open Sans',
});
const CircularProgressBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '400px'
})
const StyledCircularProgress = styled(CircularProgress)({
  color: '#FDD001'
})
const StyledMenuBox = styled(Box)({
  '@media (max-width: 850px)': {
    display: 'flex',
    alignItems: 'end',
    marginLeft: '5px'
  }
})
const StyledSubmitButton = styled(Button)({
  fontFamily: 'Open Sans',
  backgroundColor: "#FDD100",
  marginRight: "20px",
  color: "black",
  width: "170px",
  fontWeight: 550,
  fontSize: "medium",
  textTransform: 'none',
  '@media (max-width:460px)': {
    marginBottom: '15px'
  }
})
const StyledInputBox =styled(Box)({
    backgroundColor: "#FFF",
    border: '0.5px solid #FDD100',
    padding: "5px 10px",
    borderRadius: "5px",
    color: '#000000',
    width: '110px',
    '@media (max-width:1000px)': {
     position:'absolute',
     left:35,
     right:35
    }
})
const GoalCalculationTypography=styled(Typography)({
 fontSize: "21px",
  fontWeight: 700,
   color: '#000000' 
})
const NewButtonBox=styled(Box)({
  marginLeft: "42px",
   marginTop: "30px",
    position: "relative" 
})
const NewStyledGrid=styled(Grid)({
 display: "flex", 
 flexDirection: "row", 
 justifyContent: "space-between" 
})
const NewWeightageTypography=styled(Typography)({
  fontSize: "21px",
   fontWeight: 700,
    color: '#000000' 
})
const KPIDescriptionTypography=styled(Typography)({
  fontSize: "21px",
   fontWeight: 700,
    color: '#000000'
})
// Customizable Area End
